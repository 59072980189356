import { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Box, useTheme } from "@mui/material";

import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { useSelector } from "react-redux";
import { getCoinImage, getDatePeriod } from "../../utils/functions";

import useAuth from "../../utils/useAuth";
import TimeFrame from "../../components/analytics/timeFrame";
import FullChartBtn from "../../components/common/buttons/FullChartBtn";
import { fetchCryptoStaticsData } from "../../features/crypto/cryptoStaticsData";
import Loading from "../common/loading";
import AnalyticsTitle from "../common/title/AnalyticsTitle";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../utils/userPhoto";
import { isEmpty } from "lodash";

type Visibility = {
  SPS: boolean;
  Price: boolean;
  Sentiment: boolean;
  TweetCount: boolean;
  Influencers: boolean;
};

type InfluencersTyps =
  | "Tweet Count"
  | "Buy SPS"
  | "Not-Buy SPS"
  | "Positive Sentiment"
  | "Negative Sentiment";

const color: any = {
  SPS: "#00AEEF",
  Price: "#116A7B",
  Sentiment: "#A5B68D",
  TweetCount: "#D49A89",
  Influencers: "#A888B5",
};

const FullChart = (selectedCoin: any) => {
  const navigate = useNavigate();

  // timeframe states
  const [socialActivityTimeFrame, setSocialActivityTimeFrame] =
    useState<string>("1W");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const cryptoState: any = useSelector(
    (state: RootState) => state.crypto || {}
  );

  // influencer id state
  const [influencerIndex, setInfluencerIndex] = useState<number | null>(null);

  const cryptocoinsStatistics = useSelector(
    (state: RootState) => state.cryptoStatics.coinStatisticsState
  );
  const dispatch: AppDispatch = useAppDispatch();
  const isAuthValid = useAuth();

  const theme = useTheme();
  const [InfluencersType, setInfluencersType] =
    useState<InfluencersTyps>("Buy SPS");
  const [visibility, setVisibility] = useState<Visibility>({
    SPS: true,
    Price: false,
    Sentiment: false,
    TweetCount: false,
    Influencers: false,
  });

  useEffect(() => {
    setIsLoading(true);
    const timeFrames = ["1W", "2W", "1M", "3M"];
    if (isAuthValid) {
      timeFrames.forEach((timeFrame) => {
        const { startDate, endDate } = getDatePeriod(timeFrame);
        const ChartCountReq = {
          coinId: cryptoState.analyticsSelectedCoin,
          startDate,
          endDate,
          timeFrame,
        };
        dispatch(fetchCryptoStaticsData(ChartCountReq)).finally(
          // @ts-ignore
          setIsLoading(false)
        );
      });
    }
  }, [cryptoState.analyticsSelectedCoin, isAuthValid]);

  function formatPrice(price: any) {
    if (Math.abs(price) < 1) {
      return price.toFixed(8);
    } else {
      return parseFloat(price.toFixed(2));
    }
  }

  const pricesData = useMemo(() => {
    if (
      cryptocoinsStatistics &&
      cryptoState.analyticsSelectedCoin &&
      socialActivityTimeFrame
    ) {
      const allPrices = cryptocoinsStatistics[
        socialActivityTimeFrame
      ]?.price?.map((price: any) => ({
        date: moment.utc(price.start_date),
        price: formatPrice(price.price),
      }));
      const sortData = allPrices?.sort(
        (a: any, b: any) => new Date(a.x).getTime() - new Date(b.x).getTime()
      );
      return sortData;
    }
    return [];
  }, [
    cryptocoinsStatistics,
    cryptoState.analyticsSelectedCoin,
    socialActivityTimeFrame,
  ]);

  const sps = useMemo(() => {
    if (
      cryptocoinsStatistics &&
      cryptoState.analyticsSelectedCoin &&
      socialActivityTimeFrame
    ) {
      const allSPS = cryptocoinsStatistics[socialActivityTimeFrame]?.sps?.map(
        (sps: any) => ({
          date: moment.utc(sps.start_date),
          sps: +sps.sps.toFixed(2),
        })
      );

      const sortData = allSPS?.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      return sortData;
    }
    return [];
  }, [
    cryptocoinsStatistics,
    cryptoState.analyticsSelectedCoin,
    socialActivityTimeFrame,
  ]);

  const sentiment = useMemo(() => {
    if (
      cryptocoinsStatistics &&
      cryptoState.analyticsSelectedCoin &&
      socialActivityTimeFrame
    ) {
      const allSentiment = cryptocoinsStatistics[
        socialActivityTimeFrame
      ]?.sentiment?.map((sentiment: any) => ({
        date: moment.utc(sentiment.start_date),
        sentiment_avg: +(sentiment.sentiment_avg * 100).toFixed(2),
      }));

      const sortData = allSentiment?.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      return sortData;
    }
    return [];
  }, [
    cryptocoinsStatistics,
    cryptoState.analyticsSelectedCoin,
    socialActivityTimeFrame,
  ]);

  const tweet_count = useMemo(() => {
    if (
      cryptocoinsStatistics &&
      cryptoState.analyticsSelectedCoin &&
      socialActivityTimeFrame
    ) {
      const allTweetCount = cryptocoinsStatistics[
        socialActivityTimeFrame
      ]?.tweet_count?.map((tweet_count: any) => ({
        date: moment.utc(tweet_count.start_time),
        tweet_count: +tweet_count.tweet_count.toFixed(2),
      }));

      const sortData = allTweetCount?.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      return sortData;
    }
    return [];
  }, [
    cryptocoinsStatistics,
    cryptoState.analyticsSelectedCoin,
    socialActivityTimeFrame,
  ]);

  const influencersDataPoints = useMemo(() => {
    if (!cryptocoinsStatistics[socialActivityTimeFrame]) return [];

    const visibleData = Object.entries(visibility).filter(
      ([_, value]) => value
    );

    const yAxisIndexMap: Record<string, string> = {
      "Buy SPS": "SPS",
      "Not-Buy SPS": "SPS",
      "Positive Sentiment": "Sentiment",
      "Negative Sentiment": "Sentiment",
      "Tweet Count": "TweetCount",
    };

    const yAxisIndex =
      visibleData.findIndex(
        ([key]) => key === yAxisIndexMap[InfluencersType]
      ) ?? -1;

    const influencersTypeMap: Record<string, string> = {
      "Buy SPS": "top_positive_sps_influencers",
      "Not-Buy SPS": "top_negative_sps_influencers",
      "Positive Sentiment": "top_positive_sentiment_influencers",
      "Negative Sentiment": "top_negative_sentiment_influencers",
      "Tweet Count": "top_tweet_influencers",
    };

    const datasetMap: Record<string, any[]> = {
      "Buy SPS": sps,
      "Not-Buy SPS": sps,
      "Positive Sentiment": sentiment,
      "Negative Sentiment": sentiment,
      "Tweet Count": tweet_count,
    };

    const influencersList =
      cryptocoinsStatistics[socialActivityTimeFrame][
        influencersTypeMap[InfluencersType]
      ];

    if (!influencersList) return [];

    const generatePoints = (inf: any, index: number) => {
      const dataset = datasetMap[InfluencersType];
      const matchedData = dataset.find(
        (item) => moment(item.date).format("YYYY-MM-DD") === inf.date
      );

      return {
        mouseEnter: () => setInfluencerIndex(index),
        mouseLeave: () => setInfluencerIndex(null),
        click: () => navigate(`/influencers/${inf.screen_name}`),
        yAxisIndex: yAxisIndex === -1 ? 0 : yAxisIndex,
        x: matchedData ? new Date(matchedData.date).getTime() : null,
        y: matchedData
          ? InfluencersType === "Tweet Count"
            ? matchedData.tweet_count
            : (matchedData.sentiment_avg ?? matchedData.sps)
          : null,
        marker: { size: 0 },
        image: {
          path: getUserProfile(inf.screen_name),
          offsetY: 0,
          offsetX: 0,
          width: influencerIndex === index ? 35 : 25,
          height: influencerIndex === index ? 35 : 25,
        },
        label: {
          offsetY: -50,
          textAnchor: "left",
          text:
            influencerIndex === index
              ? [
                  `Screen Name : ${inf.screen_name}`,
                  `Name : ${inf.name}`,
                  `Followers Count : ${inf.followers_count.toLocaleString()}`,
                ]
              : undefined,
          style: {
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontFamily: "Poppins",
            padding: { left: 5, right: 5, top: 3, bottom: 5 },
          },
        },
      };
    };

    return influencersList.map(generatePoints);
  }, [
    InfluencersType,
    visibility,
    cryptocoinsStatistics,
    socialActivityTimeFrame,
    influencerIndex,
  ]);

  // @ts-ignore
  const chartOptions: ApexOptions = {
    annotations: {
      points: visibility?.Influencers ? (influencersDataPoints ?? []) : [],
    },
    chart: {
      height: 450,
      type: "line",
      stacked: false,
      toolbar: {
        show: true,
        offsetY: -20,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
          customIcons: [],
        },
      },
    },
    grid: {
      borderColor: "rgba(161,161,189,0.2)",
    },
    stroke: {
      width: [2, 2, 2, 2, 2],
      curve: "smooth",
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: true,
      },
      labels: {
        datetimeUTC: false,
        style: {
          colors: theme.palette.mode === "dark" ? "#f8f6f6" : "#050505",
          fontSize: "12px",
          fontFamily: "Arial",
        },
      },
      axisTicks: {
        show: false,
        borderType: "solid",
      },
      crosshairs: {
        show: false,
      },
    },
    // @ts-ignore
    yaxis: Object.entries(visibility)
      .filter((i) => i[0] !== "Influencers")
      .filter((item) => item[1] === true)
      .map((i) => ({
        show: true,
        opposite: true,
        tooltip: {
          enabled: true,
        },
        axisTicks: {
          show: false,
          borderType: "solid",
        },
        axisBorder: {
          show: true,
        },
        crosshairs: {
          show: false,
        },
        title: {
          text: i[0] as string,
          style: {
            color: color[`${i[0]}`] as string,
          },
        },
        labels: {
          style: {
            colors: theme.palette.mode === "dark" ? "#f8f6f6" : "#050505",
            fontSize: "12px",
            fontFamily: "Arial",
          },
          formatter: (value: number): string => {
            if (i[0] === "Price")
              return `$ ${formatPrice(value).toLocaleString()}`;
            if (i[0] === "Sentiment") return `${value.toLocaleString()}%`;
            if (i[0] === "SPS") return `${Math.round(value)}%`;
            if (i[0] === "TweetCount")
              return `${Math.round(value).toLocaleString()} tweets`;
            return value.toString();
          },
        },
      })),
    legend: {
      labels: {
        colors: theme.palette.mode === "dark" ? "#f8f6f6" : "#050505",
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    tooltip: {
      fixed: { enabled: true, position: "topLeft" },
      shared: true,
      intersect: false,
      theme: "dark",
      x: {
        show: true,
        format: "yyyy MMM dd HH:mm",
        formatter: undefined,
      },
      y: {
        formatter: (value: number, { seriesIndex, w }: any): string => {
          const seriesName = w.config.series[seriesIndex].name;

          if (seriesName === "SPS") return value.toLocaleString() + "%";
          if (seriesName === "Price")
            return "USD " + formatPrice(value).toLocaleString();
          if (seriesName === "Sentiment")
            return value.toLocaleString() + " Emotional Sentiment";
          if (seriesName === "TweetCount")
            return value.toLocaleString() + " tweets";

          return value.toString();
        },
      },
    },
  };

  // @ts-ignore
  const chartSeries = [
    visibility?.SPS && {
      name: "SPS",
      color: color.SPS,
      data: sps?.map((item: any) => ({
        x: new Date(item.date).getTime(),
        y: item.sps,
      })),
    },
    visibility?.Price && {
      name: "Price",
      color: color.Price,
      data: pricesData?.map((item: any) => ({
        x: new Date(item.date).getTime(),
        y: item.price,
      })),
    },
    visibility?.Sentiment && {
      name: "Sentiment",
      color: color.Sentiment,
      data: sentiment?.map((item: any) => ({
        x: new Date(item.date).getTime(),
        y: item.sentiment_avg,
      })),
    },
    visibility?.TweetCount && {
      name: "TweetCount",
      color: color.TweetCount,
      // @ts-ignore
      data: tweet_count?.map((item: any) => ({
        x: new Date(item.date).getTime(),
        y: item.tweet_count,
      })),
    },
  ].filter(Boolean);

  const toggleVisibility = (team: keyof Visibility) => {
    setVisibility((prev) => ({
      ...prev,
      [team]: !prev[team],
    }));
  };

  const activateVisibility = (team: keyof Visibility) => {
    setVisibility((prev) => ({
      ...prev,
      [team]: true,
    }));
  };

  return (
    <>
      <AnalyticsTitle
        isLarge
        title="Coinfident Crypto Insights"
        icon={
          <img
            src={getCoinImage(cryptoState.analyticsSelectedCoin)}
            width="35px"
            height="35px"
            style={{ marginRight: "10px" }}
          />
        }
      />
      <Box key={cryptoState.analyticsSelectedCoin} padding={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 5,
          }}
        >
          <Box
            style={{
              marginTop: "20px",
              marginLeft: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <FullChartBtn
                keyword="SPS"
                title="SPS"
                color={color.SPS}
                description="SPS is our proprietary AI-driven index based on crypto influencers' and news outlets' tweets"
                visiblity={visibility}
                setVisibility={setVisibility}
                activateVisibility={activateVisibility}
                onClick={() => toggleVisibility("SPS")}
              />
            </Box>

            <Box sx={{ marginRight: "10px" }}>
              <FullChartBtn
                keyword="Price"
                title="Price"
                color={color.Price}
                description="Coin price in the specified time period"
                visiblity={visibility}
                setVisibility={setVisibility}
                activateVisibility={activateVisibility}
                onClick={() => toggleVisibility("Price")}
              />
            </Box>

            <Box sx={{ marginRight: "10px" }}>
              <FullChartBtn
                keyword={"Sentiment"}
                title="Sentiment"
                color={color.Sentiment}
                description="Ranks crypto influencers based on the number of tweets with negative emotional sentiment they published in the past week, from highest to lowest."
                visiblity={visibility}
                setVisibility={setVisibility}
                activateVisibility={activateVisibility}
                onClick={() => toggleVisibility("Sentiment")}
              />
            </Box>

            <Box sx={{ marginRight: "10px" }}>
              <FullChartBtn
                keyword="TweetCount"
                title="Social Media Activity"
                color={color.TweetCount}
                description="This indicator shows the fluctuation in the number of X posts from influencers and news outlets over time for each coin."
                visiblity={visibility}
                setVisibility={setVisibility}
                activateVisibility={activateVisibility}
                onClick={() => toggleVisibility("TweetCount")}
              />
            </Box>

            <Box sx={{ marginRight: "10px" }}>
              <FullChartBtn
                keyword="Influencers"
                title="Influencers"
                color={color.Influencers}
                description="this indicators shows top influecenser activity over time for each coin"
                visiblity={visibility}
                setVisibility={setVisibility}
                activateVisibility={activateVisibility}
                onClick={() => toggleVisibility("Influencers")}
                InfluencersType={InfluencersType}
                setInfluencersType={setInfluencersType}
              />
            </Box>
          </Box>
          <Box sx={{ marginTop: "15px", marginRight: "10px" }}>
            <TimeFrame
              timePeriod={socialActivityTimeFrame}
              setTimePeriod={setSocialActivityTimeFrame}
              timeFrames={["1W", "2W", "1M", "3M"]}
              isFullChart={true}
              color={
                theme.palette.mode === "dark"
                  ? theme.palette.common.white
                  : theme.palette.common.black
              }
            />
          </Box>
        </Box>
        {isLoading ? (
          <Loading />
        ) : (
          <Box id="crypto-insight-chart">
            <ReactApexChart
              options={chartOptions}
              // @ts-ignore
              series={chartSeries}
              type="line"
              height={450}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default FullChart;
