import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import Header from "../../components/layout/header";
import Footer from "../../components/footer";
import Agent from "../../components/layout/agent";
import ResponsiveMenu from "../../components/layout/responsiveMenu";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import { fetchAvailableCoins } from "../../features/crypto/cryptosSlice";
import { LayoutProps } from "../interfaces";
import LimitAccessModal from "../../components/common/limitAccessModal";
import ScrollupBtn from "../../components/common/scrollupBtn/scrollupBtn";
import { HiArrowNarrowLeft } from "react-icons/hi";
import TawkToChat from "../../components/common/tawkToChat/TawkToChat";
const { Helmet } = require("react-helmet");

const DashboardLayout: React.FC<LayoutProps> = ({ children, title, description, hasBackBtn }) => {

  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const coinsList = useSelector((state: RootState) => state.cryptos);
  const userState: any = useSelector((state: RootState) => state.account);
  const isAgentPage = location.pathname.includes("/agents");
  const [showScrollTopBtn, setShowScrollTopBtn] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!coinsList.availableCoins) {
      dispatch(fetchAvailableCoins());
    }
  }, [coinsList.availableCoins, dispatch]);

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        <meta name="description" content={description ?? "AI powered social intelligence for crypto traders"} />
      </Helmet>
      <TawkToChat />
      <Fade>
        <Box
          style={{
            padding: 0,
            position: "relative",
            zIndex: 10,
            paddingBottom: downLg ? "3.5rem" : 0,
          }}
        >
          <Header />
          <Box
            sx={{
              flex: 1,
              position: "relative",
              padding: "20px 0 0",
              zIndex: 100,
            }}
          >
            <Box ml={downLg ? 0 : location.pathname.includes("/agents") ? "270px" : 0} style={{ minHeight: "800px" }}>
              {hasBackBtn && (
                <IconButton
                  size="small"
                  onClick={() => navigate(-1)}
                  style={{
                    position: "absolute",
                    top: "30px",
                    left: "15px",
                    zIndex: 200000,
                  }}
                >
                  <HiArrowNarrowLeft color={theme.palette.text.primary} size={32} />
                </IconButton>
              )}
              {children}
            </Box>
          </Box>

          {/* {!isAgentPage && <Agent />} */}

          <Footer />
          {downLg && <ResponsiveMenu />}
          <LimitAccessModal />
        </Box>
      </Fade>
    </>
  );
};

export default DashboardLayout;
