import { useState, useRef, useMemo } from "react";
import {
  Box,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IoFilter } from "react-icons/io5";
import { AllInfluencersProps, InfluencerDataType } from "../interfaces";
import Loading from "../../common/loading";
import MuiPagination from "../../common/pagination";
import FullPageBlur from "../../common/fullPageBlur";
import InfluencerCard from "../../influencers/influencerCard";
import InfluencersFilter from "../../influencers/influencersFilter";
import InfluencersTabSelector from "../influencersTabSelector";
import InfluencersRankTable from "../influencersRankTable";
import InfluencersSortTables from "../influencersSortTables";
import InfluencersTimeFrame from "../influencersTimeFrame";
import InfluencersSearch from "../influencersSearch";
import InfluencerFullTable from "../influencerFullTable";
import SortedRankTable from "../influencersSortedRankTable";

const AllInfluencers: React.FC<AllInfluencersProps> = ({
  influencersList,
  styles,
  isFilterOpen,
  setIsFilterOpen,
  selectedFilter,
  setSelectedFilter,
  filterInfluencers,
  filterHandler,
  page,
  influencersPaginationHandler,
  setSelectedCoin,
  timePeriod,
  setTimePeriod,
}) => {
  const theme = useTheme();
  const clickedBtnRef = useRef();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [type, setType] = useState<"tile" | "table" | "sort" | "fullTable">(
    "sort"
  );

  const [clickedInfluencerData, setClickedInfluencerData] = useState<null | {
    id_str: string | number;
    screen_name: string;
  }>(null);
  return (
    <>
      {isFilterOpen && (
        <InfluencersFilter
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          filterInfluencers={filterInfluencers}
        />
      )}
      <FullPageBlur
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        closeFullBlurFunction={() => setClickedInfluencerData(null)}
      />
      <FullPageBlur isVisible={isFilterOpen} setIsVisible={setIsFilterOpen} />
      <Box
        ref={clickedBtnRef}
        mt={downLg ? 0 : 3}
        className={styles.influencersPage}
      >
        <Box>
          <Box sx={{ position: "relative" }} mx={downLg ? 0 : 10}>
            <Box sx={{ paddingTop: downLg ? 8 : 0 }}>
              <Typography
                align="center"
                variant={downLg ? "h5" : "h4"}
                fontWeight="bold"
                color={theme.palette.text.primary}
              >
                {type === "sort"
                  ? "Coinfident's Influencers Leaderboard"
                  : "Coinfident Crypto Influencers"}
              </Typography>
              <Typography
                align="center"
                variant="h6"
                fontWeight="bold"
                color={theme.palette.text.primary}
              >
                {type === "sort" && "Top Crypto Voices Ranking"}
              </Typography>
            </Box>
            {type === "tile" && (
              <IconButton
                aria-label="filter"
                size="small"
                className={styles.influencersFilter}
                onClick={filterHandler}
              >
                <IoFilter
                  size={22}
                  //@ts-ignore
                  color={theme.palette.common.whiteToGreen}
                />
              </IconButton>
            )}

            <Box className={styles.influencersTab}>
              <InfluencersTabSelector type={type} setType={setType} />
            </Box>
          </Box>

          {type === "tile" ? (
            <Container>
              <Box className={styles.influencersWrapper} my={6}>
                {influencersList.pinnedInfluencers?.results &&
                  influencersList.pinnedInfluencers.results.map(
                    (inf: InfluencerDataType) => (
                      <InfluencerCard
                        key={inf.id_str}
                        influencerData={inf}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        clickedInfluencerData={clickedInfluencerData}
                        setClickedInfluencerData={setClickedInfluencerData}
                        isPinned={
                          influencersList.pinnedInfluencers?.results.findIndex(
                            (item: { id_str: number | string }) =>
                              item.id_str === inf.id_str
                          ) > -1
                            ? true
                            : false
                        }
                      />
                    )
                  )}
              </Box>
              <InfluencersSearch />
              <Box className={styles.influencersWrapper}>
                {influencersList.influencersList?.results &&
                  influencersList.influencersList?.results.map(
                    (inf: InfluencerDataType) => (
                      <InfluencerCard
                        key={inf.id_str}
                        influencerData={inf}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        clickedInfluencerData={clickedInfluencerData}
                        setClickedInfluencerData={setClickedInfluencerData}
                        isPinned={
                          influencersList.pinnedInfluencers?.results.findIndex(
                            (item: { id_str: number | string }) =>
                              item.id_str === inf.id_str
                          ) > -1
                            ? true
                            : false
                        }
                      />
                    )
                  )}
              </Box>
            </Container>
          ) : type === "table" ? (
            <Container>
              <Box mt="3rem">
                <Box mb={2}>
                  <InfluencersSearch />
                </Box>
                <InfluencersRankTable
                  data={influencersList.influencersList?.results}
                  pinnedList={influencersList.pinnedInfluencers?.results}
                />
              </Box>
            </Container>
          ) : type === "sort" ? (
            <Box px={downLg ? 0 : 10}>
              <Typography
                mt={downLg ? 2 : "5rem"}
                mb="3rem"
                mx={downLg ? "auto" : "4rem"}
                textAlign="center"
                variant={downLg ? "body2" : "body1"}
                color={theme.palette.text.primary}
              >
                At Coinfident, we rank genuine crypto influencers using various
                metrics, including tweet count, buy and not-buy Social
                Prediction Signals (SPS), positive and negative emotional
                sentiment, and the number of Bitcoin-related tweets.
              </Typography>
              <InfluencersSortTables
                setSelectedCoin={setSelectedCoin}
                dependedCoinData={influencersList.sortedInfluencersSelectedCoin}
                independedCoindata={influencersList?.sortedInfluencers}
                pinnedList={influencersList.pinnedInfluencers?.results}
                timePeriod={timePeriod}
                setTimePeriod={setTimePeriod}
              />
            </Box>
          ) : (
            <Box px={downLg ? 0 : 10}>
              <Typography
                mt={downLg ? 2 : "5rem"}
                mb="3rem"
                mx={downLg ? "auto" : "4rem"}
                textAlign="center"
                variant={downLg ? "body2" : "body1"}
                color={theme.palette.text.primary}
              >
                At Coinfident, we rank genuine crypto influencers using various
                metrics, including tweet count, buy and not-buy Social
                Prediction Signals (SPS), positive and negative emotional
                sentiment, and the number of Bitcoin-related tweets.
              </Typography>
              <SortedRankTable />
            </Box>
          )}
        </Box>
        {!influencersList.influencersListLoading &&
        type !== "sort" &&
        type !== "fullTable" ? (
          <MuiPagination
            page={page}
            count={Math.ceil(influencersList.influencersList?.count / 100)}
            handleChange={influencersPaginationHandler}
          />
        ) : null}
      </Box>
    </>
  );
};

export default AllInfluencers;
