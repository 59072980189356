import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { getDifferenceInMonth } from "../../../utils/functions";
import { TransactionItem, TransactionTableProps } from "./interface";
import moment from "moment";

const TransactionTable: React.FC<TransactionTableProps> = ({
  tHead,
  tBody,
}) => {
  const theme = useTheme();
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tHead.map((head: string, index: number) => (
              <TableCell key={head} align={index === 0 ? "left" : "center"}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tBody?.map((row: TransactionItem, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="left">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {row.payment_method_details.type}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {row.payment_method_details.card.brand} -{" "}
                  {row.payment_method_details.card.last4}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {row.currency === "usd" ? "$" : "₹"}
                  {row.amount / 100}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionTable;
