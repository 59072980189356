import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { getDifferenceInMonth } from "../../../utils/functions";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface ActiveSubscriptionAccordionProps {
  data: any;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActiveSubscriptionAccordion: React.FC<ActiveSubscriptionAccordionProps> = ({ data, setOpenModal }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      {data?.map((item: any) => (
        <Accordion>
          <AccordionSummary expandIcon={<MdKeyboardArrowDown size={25} />} aria-controls="panel1-content" id="panel1-header">
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} pr={1}>
              <Typography color={theme.palette.text.primary}>{item?.plan}</Typography>
              <Typography
                color={theme.palette.text.primary}
              >{`${getDifferenceInMonth(item?.current_period_start, item?.current_period_end).durationIntervalCount} ${getDifferenceInMonth(item?.current_period_start, item?.current_period_end).durationIntervalType}`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Start
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {moment(item?.current_period_start).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                End
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {moment(item?.current_period_end).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              color={item?.plan === "Free" ? "primary" : "error"}
              size="small"
              onClick={() => (item?.plan === "Free" ? navigate("/subscription") : setOpenModal(true))}
            >
              {item?.plan === "Free" ? "Upgrade" : "Cancel"}
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ActiveSubscriptionAccordion;
