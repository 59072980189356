import { Link } from "react-router-dom";
import { coinDataType } from "../interface";
import { Badge, Box, IconButton, Typography, useTheme } from "@mui/material";
import DefaultTooltip from "../../../common/tooltip";
import { RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../features/store";
import { useState } from "react";
import { pinCryptoHandler, unpinCryptoHandler } from "../../../../api/crypto";
import toast from "react-hot-toast";
import { fetchPinnedCoins } from "../../../../features/crypto/cryptosSlice";
import {
  calculateGuageValue,
  formatPrice,
  getCoinImage,
} from "../../../../utils/functions";
import styles from "../screeners.module.scss";
import LoginAlertModal from "../../../common/loginAlertModal";

interface AssetsProps {
  coin: coinDataType;
}

const Assets: React.FC<AssetsProps> = ({ coin }) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();

  const accountState = useSelector((state: RootState) => state.account);
  const cryptosState = useSelector((state: RootState) => state.cryptos);

  //states
  const [pinLoading, setPinLoading] = useState<boolean>(false);
  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);

  const isCryptoPinned = (crypto_id: number): number =>
    cryptosState.pinnedCoins?.findIndex(
      (pinnedCoin: coinDataType) => +pinnedCoin.id === crypto_id
    ) ?? -1;

  const watchListHandler = async (
    event: React.MouseEvent<HTMLButtonElement>,
    coin_id: number
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (!accountState.isAuthenticated) {
      setLoginAlertModal(true);
      return;
    }

    setPinLoading(true);

    try {
      if (isCryptoPinned(coin_id) > -1) {
        const res = await unpinCryptoHandler(coin_id);
        if (res.status === 204) {
          toast.success("Crypto has been successfully unpinned");
        }
      } else {
        const res = await pinCryptoHandler(coin_id);
        if (res.status === 201) {
          toast.success("Crypto pinned successfully");
        }
      }
      dispatch(fetchPinnedCoins());
    } finally {
      setPinLoading(false);
    }
  };

  const calcSPSChangeRate = (coin: coinDataType): string => {
    const currentValue = calculateGuageValue(
      coin.signal[0] ?? 0,
      coin.signal[1] ?? 0,
      coin.signal.null ?? 0
    );

    const lastValue = calculateGuageValue(
      coin.last_signal[0] ?? 0,
      coin.last_signal[1] ?? 0,
      coin.last_signal.null ?? 0
    );

    return currentValue - lastValue > 0
      ? styles.greenPulse
      : currentValue - lastValue < 0
        ? styles.redPulse
        : "";
  };

  return (
    <>
      <Box className={styles.assetCell}>
        <IconButton
          size="small"
          sx={{ marginLeft: ".2rem" }}
          onClick={(e) => watchListHandler(e, coin.coin_id)}
          disabled={pinLoading}
        >
          {isCryptoPinned(coin.coin_id) > -1 ? (
            <RiPushpin2Fill size={15} color={theme.palette.primary.main} />
          ) : (
            <RiPushpin2Line size={15} />
          )}
        </IconButton>
        <Link
          to={`/prices/${coin.full_id}/`}
          state={{ coin_id: coin.coin_id }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <Badge
                overlap="circular"
                badgeContent={
                  <DefaultTooltip title="Based on SPS Change">
                    <div className={calcSPSChangeRate(coin)} />
                  </DefaultTooltip>
                }
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <img
                  src={getCoinImage(coin.coin_id)}
                  alt={coin.logo}
                  className={`${styles.coinLogo}`}
                />
              </Badge>
            </Box>
            <Box
              sx={{
                transition: "maxWidth 0.1s",
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              className="coinInfo"
            >
              <Typography
                variant="caption"
                component="h2"
                sx={{ whiteSpace: "nowrap" }}
                color={theme.palette.text.primary}
              >
                {coin.label}
              </Typography>
              <Typography
                variant="body2"
                component="h3"
                sx={{
                  whiteSpace: "nowrap",
                  paddingRight: "6px",
                }}
                color={theme.palette.text.primary}
              >
                {coin.title &&
                  coin.title[0]?.toUpperCase() + coin.title.slice(1, 10)}
                {coin.title?.length > 10 ? "..." : ""}
              </Typography>
              <Typography
                variant="body2"
                component="h3"
                sx={{ whiteSpace: "nowrap" }}
                color={theme.palette.text.primary}
              >
                $
                {formatPrice(+coin.latest_price) > 1
                  ? Number(formatPrice(+coin.latest_price)).toLocaleString()
                  : formatPrice(+coin.latest_price)}
              </Typography>
            </Box>
          </Box>
        </Link>
      </Box>

      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </>
  );
};

export default Assets;
