import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CardTitle from "../../components/common/title/CardTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../features/store";
import { useEffect, useMemo, useState } from "react";
import Empty from "../../components/common/empty/Empty";
import useAuth from "../../utils/useAuth";
import {
  cancelSubscription,
  fetchInvoicesList,
  fetchPaymentMethods,
  fetchSubscriptionsList,
  fetchTransactionsList,
} from "../../features/membership/membershipSlice";
import Loading from "../../components/common/loading";
import { getDifferenceInMonth } from "../../utils/functions";
import DefaultModal from "../../components/common/modal";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SubscriptionTable from "../../components/subscription/subscriptionTable";
import TransactionTable from "../../components/subscription/transactionTable";
import InvoiceTable from "../../components/subscription/invoiceTable";
import moment from "moment";
import PaymentMethod from "../../components/subscription/paymentMethodTable";
import TabSelector from "../../components/common/tabSelector";
import DashboardLayout from "../../layout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import ActiveSubscriptionAccordion from "../../components/common/accordion/ActiveSubscriptionAccordion";
import SubscriptionAccordion from "../../components/common/accordion/SubscriptionAccordion";
import TransactionAccordion from "../../components/common/accordion/TransactionAccordion";
import InvoiceAccordion from "../../components/common/accordion/InvoiceAccordion";
import PaymentMethodAccordion from "../../components/common/accordion/PaymentMethodAccordion";
import DefaultSelect from "../../components/common/select/DefaultSelect";

const tabs: string[] = ["Active Subscription", "Subscription History", "Transactions List", "Invoice List", "Payment Methods"];

const activeSubscriptionTHead = ["Plan", "Duration", "Creation date", "Expiration date", "Status", "Action"];

const subscriptionsListTHeads = ["Plan", "Duration", "Creation date", "Expiration date", "Status"];

const transactionsListTHeads = ["Type", "Details", "Amount", "Created"];

const invoiceListTHeads = ["Created", "Payment Method", "Status", "invoice"];

const paymentMethodsTHeads = ["Card Number", "Name", "Brand", "Expiration date"];

type SubscriptionType = {
  current_period_end: string;
  current_period_start: string;
  status: string;
  id: string;
  plan: string;
};

const SubscriptionManagement = () => {
  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const membershipState: any = useSelector((state: RootState) => state.membership);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isCancelSubsLoading, setCancelSubsLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selected, setSelected] = useState<string>("Active Subscription");

  useEffect(() => {
    if (isAuthValid) {
      if (!membershipState.subscriptionsList) {
        dispatch(fetchSubscriptionsList());
      }

      if (!membershipState.transactionsList) {
        dispatch(fetchTransactionsList());
      }

      if (!membershipState.invoicesList) {
        dispatch(fetchInvoicesList());
      }

      if (!membershipState.paymentMethod) {
        dispatch(fetchPaymentMethods());
      }
    }
  }, [isAuthValid]);

  const activeSubscriptionData = useMemo<SubscriptionType | undefined>(() => {
    if (membershipState.subscriptionsList && membershipState.subscriptionsList.length > 0) {
      return membershipState.subscriptionsList?.find(
        (subscription: SubscriptionType) => subscription?.status === "active" || subscription?.status === "trialing"
      );
    }
  }, [membershipState.subscriptionsList]);

  const handleDeactiveSubscription = async () => {
    setCancelSubsLoading(true);
    await dispatch(cancelSubscription(activeSubscriptionData?.id ?? ""))
      .unwrap()
      .then(async (res) => {
        setCancelSubsLoading(false);
        toast.success("Subscription successfully deactivated");
        setOpenModal(false);
        if (res.status === 200) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          dispatch(fetchSubscriptionsList());
        }
      })
      .catch((err) => {
        setCancelSubsLoading(false);
        toast.error("Somethings went wrong, please try again later");
      });
  };

  const deactivateModalContent = (
    <Box>
      <Typography mb={5} mt={1} color={theme.palette.text.primary}>
        Are you sure you want to deactivate your current subscription?
      </Typography>
      <Box display={"flex"} gap={2}>
        <Button variant="contained" onClick={() => setOpenModal(false)}>
          Keep Subscription
        </Button>
        <LoadingButton variant="contained" color={"error"} onClick={handleDeactiveSubscription} loading={isCancelSubsLoading}>
          Confirm Deactivation
        </LoadingButton>
      </Box>
    </Box>
  );

  const tabPanels: Record<string, React.ReactNode> = {
    "Active Subscription": (
      <Box mb={2}>
        {membershipState?.subscriptionsListLoading ? (
          <Loading />
        ) : activeSubscriptionData ? (
          <>
            {isDownMD ? (
              <ActiveSubscriptionAccordion data={[activeSubscriptionData]} setOpenModal={setOpenModal} />
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {activeSubscriptionTHead.map((head: string, index: number) => (
                        <TableCell key={head} align={index === 0 ? "left" : "center"}>
                          {head}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <Typography variant="caption" fontWeight={100} color={theme.palette.text.primary}>
                          {activeSubscriptionData?.plan}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption" fontWeight={100} color={theme.palette.text.primary}>
                          {`${getDifferenceInMonth(activeSubscriptionData?.current_period_start, activeSubscriptionData?.current_period_end).durationIntervalCount} ${getDifferenceInMonth(activeSubscriptionData?.current_period_start, activeSubscriptionData?.current_period_end).durationIntervalType}`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption" fontWeight={100} color={theme.palette.text.primary}>
                          {moment(activeSubscriptionData?.current_period_start).format("YYYY-MM-DD HH:mm:ss")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption" fontWeight={100} color={theme.palette.text.primary}>
                          {moment(activeSubscriptionData?.current_period_end).format("YYYY-MM-DD HH:mm:ss")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="caption" fontWeight={100} color={theme.palette.text.primary}>
                          {activeSubscriptionData?.status}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          // disabled={
                          //   activeSubscriptionData?.status === "trialing"
                          // }
                          variant="contained"
                          color={activeSubscriptionData?.plan === "Free" ? "primary" : "error"}
                          size="small"
                          onClick={() => (activeSubscriptionData?.plan === "Free" ? navigate("/subscription") : setOpenModal(true))}
                        >
                          {activeSubscriptionData?.plan === "Free" ? "Upgrade" : "Cancel"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <Empty text="There is no active subscription" />
        )}
      </Box>
    ),
    "Subscription History": (
      <Box>
        {membershipState?.subscriptionsListLoading ? (
          <Loading />
        ) : membershipState.subscriptionsList && membershipState.subscriptionsList.length > 0 ? (
          isDownMD ? (
            <SubscriptionAccordion data={membershipState.subscriptionsList} />
          ) : (
            <SubscriptionTable tHead={subscriptionsListTHeads} tBody={membershipState.subscriptionsList} />
          )
        ) : (
          <Empty text="There is no subscriptions" />
        )}
      </Box>
    ),
    "Transactions List": (
      <Box>
        {membershipState.transactionsListLoading ? (
          <Loading />
        ) : membershipState?.transactionsList && membershipState?.transactionsList.length > 0 ? (
          isDownMD ? (
            <TransactionAccordion data={membershipState.transactionsList} />
          ) : (
            <TransactionTable tHead={transactionsListTHeads} tBody={membershipState.transactionsList} />
          )
        ) : (
          <Empty text="There is no transactions" />
        )}
      </Box>
    ),
    "Invoice List": (
      <Box>
        {membershipState.invoicesListLoading ? (
          <Loading />
        ) : membershipState.invoicesList && membershipState.invoicesList.length > 0 ? (
          isDownMD ? (
            <InvoiceAccordion data={membershipState.invoicesList} />
          ) : (
            <InvoiceTable tHead={invoiceListTHeads} tBody={membershipState.invoicesList} />
          )
        ) : (
          <Empty text="There is no invoices" />
        )}
      </Box>
    ),
    "Payment Methods": (
      <Box>
        {membershipState?.paymentMethodsLoading ? (
          <Loading />
        ) : membershipState.paymentMethods && membershipState.paymentMethods.length > 0 ? (
          isDownMD ? (
            <PaymentMethodAccordion data={membershipState.paymentMethods} />
          ) : (
            <PaymentMethod tHead={paymentMethodsTHeads} tBody={membershipState.paymentMethods} />
          )
        ) : (
          <Empty text="There is no connected card" />
        )}
      </Box>
    ),
  };

  return (
    <DashboardLayout title="Coinfident | Subscription Management" hasBackBtn>
      <Box mt={3} mx={isDownMD ? 1 : "15rem"}>
        <CardTitle isCenter>Subscription Management</CardTitle>

        <Box my={5}>
          {isDownMD ? (
            <DefaultSelect SelectItems={tabs} selected={selected} setSelected={setSelected} />
          ) : (
            <TabSelector tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          )}
        </Box>

        <Box>
          {isDownMD
            ? tabPanels[selected]
            : tabs.map((tab, index) => (
                <div key={index} role="tabpanel" hidden={selectedTab !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
                  {selectedTab === index && tabPanels[tab]}
                </div>
              ))}
        </Box>
      </Box>

      <DefaultModal open={openModal} setOpen={setOpenModal} title="Deactivate Subscription" content={deactivateModalContent} />
    </DashboardLayout>
  );
};

export default SubscriptionManagement;
