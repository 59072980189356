import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MembershipState } from "./interface";
import http from "../../services/http";

const initialState: MembershipState = {
  productsList: [],
  productsListLoading: true,
  pricesList: [],
  pricesListLoading: true,
  subscriptionsList: null,
  subscriptionsListLoading: true,
  invoicesList: null,
  invoicesListLoading: true,
  invoice: null,
  invoiceLoading: true,
  transactionsList: null,
  transactionsListLoading: true,
  subscriptionData: null,
  paymentMethods: null,
  paymentMethodsLoading: true,
};

export const fetchProductsList = createAsyncThunk<any, void, { rejectValue: any }>("products_list", async (_, thunkAPI) => {
  try {
    const response = await http.get("/membership/list-products/");
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPricesList = createAsyncThunk<any, void, { rejectValue: any }>("prices_list", async (_, thunkAPI) => {
  try {
    const response = await http.get("/membership/list-prices/");
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createCustomer = createAsyncThunk<any, undefined, { rejectValue: any }>("create customer", (_, thunkAPI) => {
  try {
    return http
      .post("/membership/create-customer/")
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err.response));
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const createSubscription = createAsyncThunk<any, any, { rejectValue: any }>("create subscription", (requestData, thunkAPI) => {
  try {
    return http.post("/membership/create-subscription/", requestData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSubscriptionsList = createAsyncThunk<any, void, { rejectValue: any }>("subscriptions_list", async (_, thunkAPI) => {
  try {
    const response = await http.get("/membership/list-subscriptions/");
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const cancelSubscription = createAsyncThunk<any, string, { rejectValue: any }>(
  "cancel subscription",
  (subscription_id, thunkAPI) => {
    try {
      return http.post("/membership/cancel-subscription/", {
        subscription_id,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saveCardInfo = createAsyncThunk<any, any, { rejectValue: any }>("save card info", (requestData, thunkAPI) => {
  try {
    return http.post("/membership/save-card-info/", requestData, {
      maxRedirects: 0,
      validateStatus: (status) => status >= 200 && status < 400,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const stripeCheckout = createAsyncThunk<any, any, { rejectValue: any }>("stripe check-out", (priceID, thunkAPI) => {
  try {
    return http.get(`/membership/stripe-checkout/?price_id=${priceID}`, {
      maxRedirects: 0,
      validateStatus: (status) => status >= 200 && status < 400,
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTransactionsList = createAsyncThunk<any, void, { rejectValue: any }>("transactions_list", async (userID, thunkAPI) => {
  try {
    const response = await http.get("/membership/list-transactions/");
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInvoicesList = createAsyncThunk<any, void, { rejectValue: any }>("invoices_list", async (_, thunkAPI) => {
  try {
    const response = await http.get("/membership/list-invoices/");
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchInvoice = createAsyncThunk<any, number, { rejectValue: any }>("invoice", async (userID, thunkAPI) => {
  try {
    const response = await http.get(`/membership/invoice/${userID}`);
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const confirmPayment = createAsyncThunk<any, string, { rejectValue: any }>("confirm payment", async (hashID, thunkAPI) => {
  try {
    return await http.get(`/membership/retrieve-card-info/${hashID}`);
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPaymentMethods = createAsyncThunk<any, void, { rejectValue: any }>("payment methods", async (_, thunkAPI) => {
  try {
    const response = await http.get(`/membership/payment-methods/`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsList.pending, (state) => {
        state.productsListLoading = true;
      })
      .addCase(fetchProductsList.fulfilled, (state, action) => {
        state.productsList = action.payload;
        state.productsListLoading = false;
      })
      .addCase(fetchProductsList.rejected, (state) => {
        state.productsListLoading = true;
      });
    builder
      .addCase(fetchPricesList.pending, (state) => {
        state.pricesListLoading = true;
      })
      .addCase(fetchPricesList.fulfilled, (state, action) => {
        state.pricesList = action.payload;
        state.pricesListLoading = false;
      })
      .addCase(fetchPricesList.rejected, (state) => {
        state.pricesListLoading = true;
      });
    builder
      .addCase(fetchSubscriptionsList.pending, (state) => {
        state.subscriptionsListLoading = true;
      })
      .addCase(fetchSubscriptionsList.fulfilled, (state, action) => {
        state.subscriptionsList = action.payload;
        state.subscriptionsListLoading = false;
      })
      .addCase(fetchSubscriptionsList.rejected, (state) => {
        state.subscriptionsListLoading = false;
      });
    builder
      .addCase(fetchTransactionsList.pending, (state) => {
        state.transactionsListLoading = true;
      })
      .addCase(fetchTransactionsList.fulfilled, (state, action) => {
        state.transactionsList = action.payload;
        state.transactionsListLoading = false;
      })
      .addCase(fetchTransactionsList.rejected, (state) => {
        state.transactionsListLoading = false;
      });
    builder
      .addCase(fetchInvoicesList.pending, (state) => {
        state.invoicesListLoading = true;
      })
      .addCase(fetchInvoicesList.fulfilled, (state, action) => {
        state.invoicesList = action.payload;
        state.invoicesListLoading = false;
      })
      .addCase(fetchInvoicesList.rejected, (state) => {
        state.invoicesListLoading = false;
      });
    builder
      .addCase(fetchInvoice.pending, (state) => {
        state.invoiceLoading = true;
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload;
        state.invoiceLoading = false;
      })
      .addCase(fetchInvoice.rejected, (state) => {
        state.invoiceLoading = true;
      });
    builder
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.paymentMethodsLoading = true;
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload;
        state.paymentMethodsLoading = false;
      })
      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.paymentMethods = [];
        state.paymentMethodsLoading = false;
      });
  },
});

export const { setSubscriptionData } = membershipSlice.actions;

export default membershipSlice.reducer;
