import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { PaymentMethodProps } from "./interface";

const PaymentMethod: React.FC<PaymentMethodProps> = ({ tHead, tBody }) => {
  const theme = useTheme();

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tHead.map((head: string, index: number) => (
              <TableCell key={head} align={index === 0 ? "left" : "center"}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tBody?.map((row: any, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="left">
                <Typography
                  variant="caption"
                  fontWeight={100}
                  color={theme.palette.text.primary}
                >
                  {`**** - **** - **** - ${row.card.last4}`}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  fontWeight={100}
                  color={theme.palette.text.primary}
                >
                  {row.billing_details.name || "-"}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  fontWeight={100}
                  color={theme.palette.text.primary}
                >
                  {row.card.brand}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  fontWeight={100}
                  color={theme.palette.text.primary}
                >
                  {`${row.card.exp_year}-${row.card.exp_month}`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentMethod;
