import { Box, Typography, useTheme } from "@mui/material";
import DefaultTooltip from "../../../common/tooltip";
import { headDataType } from "../interface";
import styles from "../screeners.module.scss";
import { LuInfo } from "react-icons/lu";

interface ScreenersHeader {
  column: headDataType;
  sortColumn: number;
  sortDirection: "asc" | "desc";
}

const ScreenersHeader: React.FC<ScreenersHeader> = ({
  column,
  sortColumn,
  sortDirection,
}) => {
  const theme = useTheme();

  return (
    <Box className={styles.headCell}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={column.id === 1 ? "flex-start" : "center"}
      >
        <Typography color={theme.palette.text.primary}>
          {column.title}
        </Typography>
        {column.info && (
          <DefaultTooltip title={column.info}>
            <Typography component={"span"} ml={1}>
              <LuInfo size={14} color={theme.palette.text.primary} />
            </Typography>
          </DefaultTooltip>
        )}
        <Typography ml={1}>
          {sortColumn === column.id
            ? sortDirection === "asc"
              ? "↑"
              : "↓"
            : ""}
        </Typography>
      </Box>

      <Typography color={theme.palette.text.primary}>
        {column?.moreDetails}
      </Typography>
    </Box>
  );
};

export default ScreenersHeader;
