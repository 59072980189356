import styles from "./buttons.module.scss";
import Tooltip from "../../../components/common/tooltip";
import {
  MdKeyboardArrowDown,
  MdOutlineCheck,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from "react-icons/md";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useState } from "react";

type InfluencersTyps =
  | "Tweet Count"
  | "Buy SPS"
  | "Not-Buy SPS"
  | "Positive Sentiment"
  | "Negative Sentiment";

type Visibility = {
  SPS: boolean;
  Price: boolean;
  Sentiment: boolean;
  TweetCount: boolean;
  Influencers: boolean;
};

interface FullChartBtnProps {
  keyword: keyof Visibility;
  color: string;
  title: string;
  description: string;
  visiblity: Visibility;
  setVisibility: React.Dispatch<React.SetStateAction<Visibility>>;
  activateVisibility: (key: keyof Visibility) => void;
  onClick: any;
  InfluencersType?: InfluencersTyps;
  setInfluencersType?: React.Dispatch<React.SetStateAction<InfluencersTyps>>;
}

const influencersSortItems: InfluencersTyps[] = [
  "Buy SPS",
  "Not-Buy SPS",
  "Positive Sentiment",
  "Negative Sentiment",
  "Tweet Count",
];

const FullChartBtn = ({
  keyword,
  color,
  title,
  description,
  visiblity,
  setVisibility,
  activateVisibility,
  onClick,
  InfluencersType,
  setInfluencersType,
}: FullChartBtnProps) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (sortItem: InfluencersTyps) => {
    const sortIndexMap: Record<string, keyof Visibility> = {
      "Buy SPS": "SPS",
      "Not-Buy SPS": "SPS",
      "Positive Sentiment": "Sentiment",
      "Negative Sentiment": "Sentiment",
      "Tweet Count": "TweetCount",
    };

    if (typeof sortItem === "string" && !visiblity[sortIndexMap[sortItem]]) {
      activateVisibility(sortIndexMap[sortItem]);
    }

    if (sortItem && typeof sortItem === "string") {
      setInfluencersType && setInfluencersType(sortItem);
    }
    setAnchorEl(null);
  };

  return (
    <Button
      variant="outlined"
      className={styles.fullChartBtn}
      sx={{
        color: color,
        paddingLeft: "0px",
        paddingRight: "0.5rem",
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#121413"
            : theme.palette.primary.light,
      }}
    >
      <div
        className={styles.fullChartBtn__wrapper}
        style={{ backgroundColor: color }}
      ></div>
      <Tooltip title={description} placement="top">
        <Typography
          sx={{
            color: theme.palette.mode === "dark" ? "white" : "black",
            marginRight: "0.6rem",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "3px",
            fontSize: "12px",
          }}
        >
          {title}
          <IoIosInformationCircleOutline size={14} />
        </Typography>
      </Tooltip>

      {visiblity[keyword] ? (
        <Tooltip title="Hide From Chart" placement="top">
          <button onClick={onClick} className={styles.fullChartBtn__visibel}>
            <MdOutlineVisibilityOff
              style={{
                width: "20px",
                height: "20px",
                color: color,
              }}
            />
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="Show In Chart" placement="top">
          <button onClick={onClick} className={styles.fullChartBtn__visibel}>
            <MdOutlineVisibility
              style={{
                width: "20px",
                height: "20px",
                color: color,
              }}
            />
          </button>
        </Tooltip>
      )}

      {InfluencersType && visiblity.Influencers && (
        <Box sx={{ marginLeft: "0.6rem" }}>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="outlined"
            sx={{ minWidth: "16px", px: 0.6, py: 0.1 }}
            endIcon={
              <MdKeyboardArrowDown
                size={16}
                color={theme.palette.text.primary}
              />
            }
          >
            <Typography color={theme.palette.text.primary} fontSize={10}>
              {InfluencersType}
            </Typography>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{ mt: 0.6 }}
          >
            {influencersSortItems.map((sortItem: InfluencersTyps) => (
              <MenuItem key={sortItem} onClick={() => handleClose(sortItem)}>
                {InfluencersType === sortItem && (
                  <MdOutlineCheck
                    size={16}
                    color={theme.palette.text.primary}
                  />
                )}
                <Typography
                  fontSize={"10px"}
                  color={theme.palette.text.primary}
                  sx={{ pl: InfluencersType === sortItem ? 1 : 3 }}
                >
                  {sortItem}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Button>
  );
};

export default FullChartBtn;
