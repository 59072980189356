import { Button, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

interface DefaultSelectProps {
  SelectItems: string[];
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const DefaultSelect: React.FC<DefaultSelectProps> = ({ SelectItems, selected, setSelected }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = (e: React.MouseEvent<HTMLLIElement> | Event, reason?: string) => {
    if (reason === "backdropClick") {
      setAnchorEl(null);
      return;
    }

    if ((e.target as HTMLElement).innerText) {
      setSelected((e.target as HTMLElement).innerText);
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        endIcon={<MdKeyboardArrowDown size={25} color={theme.palette.common.white} />}
        fullWidth
      >
        <Typography fontSize={14} color={theme.palette.common.white} textAlign={"left"} flex={1}>
          {selected}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(event, reason) => handleClose(event as Event, reason)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "87%",
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        {SelectItems.map((item: string) => (
          <MenuItem key={item} onClick={handleClose}>
            <Typography fontSize={12} color={theme.palette.text.primary}>
              {item}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DefaultSelect;
