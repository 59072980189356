import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography, useTheme } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";

interface InvoiceAccordionProps {
  data: any;
}

const InvoiceAccordion: React.FC<InvoiceAccordionProps> = ({ data }) => {
  const theme = useTheme();
  return (
    <>
      {data?.map((item: any) => (
        <Accordion>
          <AccordionSummary expandIcon={<MdKeyboardArrowDown size={25} />} aria-controls="panel1-content" id="panel1-header">
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} pr={1}>
              <Typography color={theme.palette.text.primary}>{item.status}</Typography>
              <Typography color={theme.palette.text.primary}>{moment(item.created).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Payment Method
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {item.collection_method}
              </Typography>
            </Box>
            <Button fullWidth color="primary" variant="contained" onClick={() => window.open(item.hosted_invoice_url, "_blank")}>
              Show
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default InvoiceAccordion;
