import React from "react";
import { useEffect } from "react";

interface TawkToChatProps {}

const TawkToChat: React.FC<TawkToChatProps> = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://embed.tawk.to/67cc1b6195ad391910035dd6/1ilqklu4g";
    script.async = true;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default TawkToChat;
