import { Box, Button, Typography, useTheme } from "@mui/material";
import DashboardLayout from "../../../layout/dashboardLayout";
import styles from "./cancel.module.scss";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Cancel: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <DashboardLayout title="Coinfident | Cancle Payment">
      <Box className={styles.cancelPaymentContainer}>
        <Box className={styles.cancelPayment}>
          <MdCancel color={theme.palette.error.main} size={90} />
          <Typography variant="h3" component="h2" mb={2} color={theme.palette.text.primary}>
            Subscription Error
          </Typography>
          <Typography variant="body1" textAlign={"center"} mb={4} color={theme.palette.text.primary}>
            We’re sorry, but we couldn’t save your card info. Please review your details and try again. Alternatively, you can return to the
            homepage or contact support for further assistance.
          </Typography>
          <Button variant="contained" fullWidth onClick={() => navigate("/")}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Cancel;
