import { Typography, useTheme } from "@mui/material";
import { formatPrice } from "../../../../utils/functions";
import CountUp from "react-countup";
import { useMemo } from "react";

interface PostChangeRateProps {
  tweetCount: number;
  lastTweetCount: number;
}

const PostChangeRate: React.FC<PostChangeRateProps> = ({
  tweetCount,
  lastTweetCount,
}) => {
  const theme = useTheme();

  const calcPostChangeRate = useMemo(() => {
    const diffrence = Number(tweetCount) - Number(lastTweetCount);
    const changeRatePercent = (diffrence / Number(lastTweetCount)) * 100;

    return changeRatePercent;
  }, [tweetCount, lastTweetCount]);

  const getColor = (rate: number) => {
    if (rate <= -3) return "#FA637A";
    if (rate > 3) return "#2FDB7D";
    return theme.palette.text.primary;
  };

  if (!tweetCount) {
    return (
      <Typography
        variant="caption"
        component="h6"
        sx={{
          whiteSpace: "nowrap",
          color: theme.palette.text.primary,
        }}
      >
        Coming soon
      </Typography>
    );
  }

  return (
    <Typography
      variant="caption"
      component="h6"
      sx={{
        whiteSpace: "nowrap",
        color: getColor(calcPostChangeRate),
      }}
    >
      {calcPostChangeRate < 0 ? "-" : calcPostChangeRate > 0 ? "+" : ""}
      <CountUp
        decimals={3}
        duration={3}
        start={0}
        end={formatPrice(Math.abs(calcPostChangeRate))}
      />
      %
    </Typography>
  );
};

export default PostChangeRate;
