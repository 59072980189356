import axios from "axios";
import { removeAuthTokens } from "./auth";
import { setIsAuthenticated } from "../features/auth/accountSlice";
import { store } from "../features/store";

const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEVELOPMENT;

const http = axios.create({
  baseURL: BASE_URL,
});

// //@ts-ignore
// http.defaults.headers = {
//   "Content-Type": "application/json",
//   Accept: "application/json",
// };

http.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      if (!originalRequest._retry && localStorage.getItem("refresh-token")) {
        originalRequest._retry = true;

        return http
          .post(
            "/auth/token/refresh/",
            {
              refresh: localStorage.getItem("refresh-token"),
            },
            //@ts-ignore
            { _retry: true }
          )
          .then((res) => {
            const newToken = res.data.access;
            // 1) put token to LocalStorage
            localStorage.setItem("access-token", newToken);

            // 2) Change Authorization header
            setAuthHeader(newToken);

            // check if url is for checking auth with old token. also set header for originalRequest
            originalRequest.headers["Authorization"] = "Bearer " + newToken;
            if (originalRequest.url === "/auth/token/verify/") originalRequest.data = JSON.stringify({ token: newToken });

            // 3) return originalRequest object with Axios.
            return http(originalRequest);
          })
          .catch((err) => {
            if (err.response.status) {
              // window.location.replace("/login");
              store.dispatch(setIsAuthenticated(false));
              removeAuthTokens();
            }
          });
      } else {
        removeAuthTokens();
      }
    }

    return Promise.reject(error);
  }
);

export function setAuthHeader(token: string) {
  if (token) http.defaults.headers["Authorization"] = "Bearer " + token;
  else delete http.defaults.headers["Authorization"];
}

export default http;
