import { Typography } from "@mui/material";
import TableTitle from "../../../common/title/TableTitle";
import StatisticsGuage from "../../../guage/StatisticsGuage";
import { calculateGuageValue } from "../../../../utils/functions";
import React from "react";

const calculateGaugeValues = (signal: { 0: number; 1: number; null: number }) =>
  calculateGuageValue(signal[0] ?? 0, signal[1] ?? 0, signal.null ?? 0);

const shouldDisplayGauge = (
  tweetCount: number,
  signal: { 0: number; 1: number; null: number }
) =>
  tweetCount >= 10 &&
  Object.values(signal).reduce((acc, curr) => acc + curr, 0) >= 10;

interface GaugeProps {
  tweetCount: number;
  signal: { 0: number; 1: number; null: number };
  lastSignal: { 0: number; 1: number; null: number };
}

const Gauge: React.FC<GaugeProps> = ({ tweetCount, signal, lastSignal }) => {
  if (shouldDisplayGauge(tweetCount, signal)) {
    return (
      <StatisticsGuage
        value={calculateGaugeValues(signal)}
        lastValue={calculateGaugeValues(lastSignal)}
      />
    );
  }

  return (
    <TableTitle
      hasInfo
      info="Given the number of tweets influencers have had in the last 24 hours, providing a signal is not available"
      infoTitle="NA"
      isCenter
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          color: "#FA637A",
          fontSize: "10px",
        }}
      >
        N/A
      </Typography>
    </TableTitle>
  );
};

export default React.memo(Gauge);
