import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import Plans from "../../components/subscription/plans";
import styles from "./subscription.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPricesList,
  fetchProductsList,
  fetchSubscriptionsList,
} from "../../features/membership/membershipSlice";
import { AppDispatch, RootState } from "../../features/store";
import useAuth from "../../utils/useAuth";
import Loading from "../../components/common/loading";
import { isEmpty } from "lodash";
import FeaturesTable from "../../components/subscription/featuresTable";

const Subscription = () => {
  const theme = useTheme();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const membershipState: any = useSelector(
    (state: RootState) => state.membership
  );

  const featureTableRef = useRef<HTMLDivElement>();

  const [selectedPlan, setSelectedPlan] = useState<string>("Basic");
  const [duration, setDuration] = useState<string>(
    JSON.stringify({
      name: "month",
      count: 1,
    })
  );

  const handleChange = (event: any, data: string) => {
    if (data !== null) {
      setDuration(data);
    }
  };

  const pricesData = useMemo(() => {
    const data = membershipState.pricesList?.filter(
      (price: any) =>
        price.recurring.interval_count === JSON.parse(duration).count &&
        price.recurring.interval === JSON.parse(duration).name
    );

    return data;
  }, [duration, membershipState.pricesList]);

  useEffect(() => {
    if (isAuthValid && isEmpty(membershipState.productsList)) {
      dispatch(fetchProductsList());
    }
  }, [isAuthValid]);

  useEffect(() => {
    if (isAuthValid && isEmpty(membershipState.pricesList)) {
      dispatch(fetchPricesList());
    }
  }, [isAuthValid]);

  useEffect(() => {
    if (isAuthValid && !membershipState.subscriptionsList) {
      dispatch(fetchSubscriptionsList());
    }
  }, [isAuthValid]);

  const scrollToFeatures = (): void =>
    featureTableRef.current?.scrollIntoView({
      behavior: "smooth",
    });

  return (
    <DashboardLayout title="Coinfident | Subscription">
      <Container maxWidth="lg">
        <Box className={styles.subscriptionWrapper} pt={3}>
          <Box className={styles.subscriptionContent}>
            <Typography
              align="center"
              variant="h4"
              color={theme.palette.text.primary}
            >
              Pricing
            </Typography>
            <Typography
              variant="body2"
              component="h2"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              Select the offer the best suits your need
            </Typography>

            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 4 }}
            >
              <ToggleButtonGroup
                color="primary"
                value={duration}
                exclusive
                onChange={handleChange}
                aria-label="duration"
              >
                <ToggleButton
                  value={JSON.stringify({ name: "month", count: 1 })}
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  1 Month
                </ToggleButton>
                <ToggleButton
                  value={JSON.stringify({ name: "month", count: 3 })}
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  3 Month
                </ToggleButton>
                <ToggleButton
                  value={JSON.stringify({ name: "month", count: 6 })}
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  6 Month
                </ToggleButton>
                <ToggleButton
                  value={JSON.stringify({ name: "year", count: 1 })}
                  size="small"
                  sx={{ padding: ".5rem .7rem" }}
                >
                  1 Year
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            {membershipState.productsListLoading ||
            membershipState.pricesListLoading ? (
              <Loading />
            ) : (
              <Plans
                setSelectedPlan={setSelectedPlan}
                selectedPlan={selectedPlan}
                duration={JSON.parse(duration)}
                pricesData={pricesData}
                scrollToFeatures={scrollToFeatures}
              />
            )}
          </Box>
        </Box>
        <Box className={styles.featuresWrapper} ref={featureTableRef}>
          <Typography
            align="center"
            variant="h4"
            color={theme.palette.text.primary}
            mb={2}
          >
            Compare Plans
          </Typography>
          {membershipState.productsListLoading ? (
            <Loading />
          ) : (
            <FeaturesTable
              pricesData={pricesData}
              duration={JSON.parse(duration)}
            />
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default Subscription;
