import { Typography, useTheme } from "@mui/material";
import {
  calculateDayPercentChange,
  formatPrice,
} from "../../../../utils/functions";
import CountUp from "react-countup";

interface PricePercentageProps {
  prices: { coin: number; date: string; avg_price: number }[];
}

const PricePercentage: React.FC<PricePercentageProps> = ({ prices }) => {
  const theme = useTheme();

  if (prices && prices.length > 0) {
    return (
      <Typography
        variant="caption"
        component="h6"
        sx={{
          whiteSpace: "nowrap",
          color: calculateDayPercentChange(prices) < 0 ? "#FA637A" : "#259659",
        }}
      >
        {calculateDayPercentChange(prices) < 0 ? "-" : "+"}
        <CountUp
          decimals={3}
          duration={3}
          start={1}
          end={formatPrice(Math.abs(calculateDayPercentChange(prices)))}
        />
        %
      </Typography>
    );
  }

  return <Typography color={theme.palette.text.primary}> - </Typography>;
};

export default PricePercentage;
