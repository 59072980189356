import {
  Avatar,
  Box,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { fetchInfluencerTableData } from "../../../features/influencers/influencersSlice";
import { calculateEndDate, calculateStartDate } from "../../../utils/functions";
import { useAppDispatch } from "../../../features/store";
import { getUserProfile } from "../../../utils/userPhoto";
import {
  InfluencerNewType,
  InfluencerType,
  SortedRankTableProps,
  TableHeadType,
} from "./interface";
import { FaArrowTrendDown, FaArrowTrendUp, FaEquals } from "react-icons/fa6";
import { Link } from "react-router-dom";
import CryptoList from "../../crypto/cryptoList";
import InfluencersTimeFrame from "../influencersTimeFrame";
import DefaultTooltip from "../../common/tooltip";
import { LuInfo } from "react-icons/lu";
import Loading from "../../common/loading";

const tableHeads: TableHeadType[] = [
  {
    key: "influencer_name",
    name: "Influencer Name",
    info: "",
  },
  {
    key: "relevant_tweets",
    name: "Coin Tweet Volume",
    info: "Ranks crypto influencers by the number of related tweets published in the past week, from highest to lowest.",
  },
  {
    key: "buy_signal",
    name: "Coin Tweet Volume Buy SPS ",
    info: "Ranks crypto influencers by the number of tweets related to coins with a 'Not-Buy SPS' signal published in the past week, from highest to lowest.",
  },
  {
    key: "sell_signal",
    name: "Coin Tweet Volume Not-Buy SPS",
    info: "Ranks crypto influencers by the number of tweets related to coins with a 'Not-Buy SPS' signal published in the past week, from highest to lowest.",
  },
  {
    key: "positive_sentiment",
    name: "Positive Emotional Sentiment",
    info: "Ranks crypto influencers based on the number of tweets with positive emotional sentiment they published in the past week, from highest to lowest.",
  },
  {
    key: "negative_sentiment",
    name: "Negative Emotional Sentiment",
    info: "Ranks crypto influencers based on the number of tweets with negative emotional sentiment they published in the past week, from highest to lowest.",
  },
  {
    key: "overall_accuracy_rank",
    name: "Accurancy Rank ",
    info: "Indicates how reliable the influencer's signals are in predicting market movements based on their tweets and subsequent price changes.",
  },
  {
    key: "engagement_rate_rank",
    name: "Engagement Rank",
    info: "Measures the level of audience interaction with the influencer's tweets, calculated based on the number of likes and retweets received.",
  },
  {
    key: "final_rank",
    name: "Final Rank",
    info: "Ranks crypto influencers based on the number of tweets with negative emotional sentiment they published in the past week, from highest to lowest.",
  },
];

const determineBadge = (delta: number): ReactElement | undefined => {
  if (delta === 0) return;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0.5,
        px: 0.5,
        borderRadius: "1rem",
        backgroundColor: delta > 0 ? "#259659" : "#FA637A",
      }}
    >
      {delta > 0 ? (
        <FaArrowTrendUp size={10} color="FFFFFF" />
      ) : (
        <FaArrowTrendDown size={10} color="FFFFFF" />
      )}
      <Typography fontSize={10}>{`${delta ?? 0}`}</Typography>
    </Box>
  );
};

const SortedRankTable: React.FC<SortedRankTableProps> = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const data = useSelector((state: any) => state.influencers.influencerTable);

  const [timePeriod, setTimePeriod] = useState<1 | 3 | 7 | 30>(7);
  const [selectedCoin, setSelectedCoin] = useState<number>(3);

  const [loading, setLoading] = useState<boolean>(false);

  const [sortBy, setSortBy] = useState<string>("influencer_name");
  const [order, setOrder] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    setLoading(true);

    const reqBody = {
      coinID: selectedCoin,
      startDate: calculateStartDate(timePeriod),
      endDate: calculateEndDate(),
      period: timePeriod,
    };

    dispatch(fetchInfluencerTableData(reqBody)).finally(() =>
      setLoading(false)
    );
  }, [timePeriod, selectedCoin]);

  const coinSelectHandeler = (coinID: number) => setSelectedCoin(coinID);

  const handleSort = (key: string) => {
    setSortBy(key);
    setOrder(order === "asc" ? "desc" : "asc");
  };

  // const transformedData: InfluencerNewType[] | undefined = useMemo(() => {
  //   if (data[timePeriod] && !isEmpty(data[timePeriod].data)) {
  //     const result = data[timePeriod].data;
  //     const uniqueInfluencers: Record<string, InfluencerNewType> = {};

  //     Object.entries(result).forEach(([subject, influencers]) => {
  //       (influencers as InfluencerType[]).forEach(
  //         (influencer: InfluencerType) => {
  //           const userId = influencer.user_id_str;

  //           if (!uniqueInfluencers[userId]) {
  //             uniqueInfluencers[userId] = {
  //               ...influencer,
  //               ranks: {
  //                 relevant_tweets: { index: null, rank_delta: null },
  //                 buy_signal: { index: null, rank_delta: null },
  //                 sell_signal: { index: null, rank_delta: null },
  //                 positive_sentiment: { index: null, rank_delta: null },
  //                 negative_sentiment: { index: null, rank_delta: null },
  //                 btc_ranking: { index: null, rank_delta: null },
  //                 engagement_rate_rank: { index: null, rank_delta: null },
  //                 overall_accuracy_rank: { index: null, rank_delta: null },
  //                 accuracy_buy_rank: { index: null, rank_delta: null },
  //                 accuracy_notbuy_rank: { index: null, rank_delta: null },
  //                 final_rank: { index: null, rank_delta: null },
  //               },
  //             };
  //           }

  //           uniqueInfluencers[userId].ranks[
  //             subject as keyof (typeof uniqueInfluencers)[typeof userId]["ranks"]
  //           ] = {
  //             index: influencer.index,
  //             rank_delta: influencer.rank_delta,
  //           };
  //         }
  //       );
  //     });

  //     return Object.values(uniqueInfluencers);
  //   }
  // }, [data]);

  const getUniqueInfluencers = useMemo(() => {
    if (data[timePeriod] && !isEmpty(data[timePeriod].data)) {
      const result = data[timePeriod].data;
      const uniqueInfluencers: Record<string, InfluencerType> = {};

      Object.values(result).forEach((influencers) => {
        (influencers as InfluencerType[]).forEach(
          (influencer: InfluencerType) => {
            const userId = influencer.user_id_str;

            if (!uniqueInfluencers[userId]) {
              uniqueInfluencers[userId] = {
                ...influencer,
              };
            }
          }
        );
      });

      return Object.values(uniqueInfluencers);
    }
  }, [data, timePeriod]);

  // const sortedData: InfluencerNewType[] | undefined = useMemo(() => {
  //   if (transformedData) {
  //     if (sortBy === "influencer_name") {
  //       return transformedData.sort(
  //         (a: InfluencerNewType, b: InfluencerNewType) => {
  //           if (order === "asc") {
  //             return a.user_name.localeCompare(b.user_name);
  //           } else {
  //             return b.user_name.localeCompare(a.user_name);
  //           }
  //         }
  //       );
  //     } else {
  //       return transformedData
  //         .filter((influencer: any) => influencer.ranks[sortBy].index !== null)
  //         .sort((a: any, b: any) => {
  //           if (order === "asc") {
  //             return a.ranks[sortBy].index - b.ranks[sortBy].index;
  //           } else {
  //             return b.ranks[sortBy].index - a.ranks[sortBy].index;
  //           }
  //         });
  //     }
  //   }
  // }, [transformedData, sortBy, order]);

  const sortedData: InfluencerType[] | undefined = useMemo(() => {
    if (data) {
      if (sortBy === "influencer_name") {
        return getUniqueInfluencers?.sort(
          (a: InfluencerType, b: InfluencerType) => {
            if (order === "asc") {
              return a.user_name.localeCompare(b.user_name);
            } else {
              return b.user_name.localeCompare(a.user_name);
            }
          }
        );
      } else {
        const cloneData: InfluencerType[] = [...data[timePeriod]?.data[sortBy]];
        if (cloneData) {
          return cloneData?.sort((a: any, b: any) => {
            if (order === "asc") {
              return a[sortBy]?.rank - b[sortBy]?.rank;
            } else {
              return b[sortBy]?.rank - a[sortBy]?.rank;
            }
          });
        }
      }
    }
  }, [data, sortBy, order]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <CryptoList coinsSelectorHandler={coinSelectHandeler} isAnalytics />
        <InfluencersTimeFrame
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
        />
      </Box>

      <TableContainer component={Paper} sx={{ maxHeight: 1000 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeads.map((head: TableHeadType, index: number) => (
                <TableCell sx={{ px: 1 }}>
                  <TableSortLabel
                    active={sortBy === head.key}
                    direction={order}
                    onClick={() => handleSort(head.key)}
                    sx={{
                      "& .MuiTableSortLabel-icon": {
                        color: `${theme.palette.text.primary} !important`,
                      },
                    }}
                  >
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                      lineHeight={1}
                    >
                      {head.name}
                      <DefaultTooltip title={head.info}>
                        <Typography
                          component={"span"}
                          ml={1}
                          hidden={index === 0}
                        >
                          <LuInfo
                            size={12}
                            color={theme.palette.text.primary}
                          />
                        </Typography>
                      </DefaultTooltip>
                    </Typography>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((influencer: InfluencerType, index: number) => (
              <TableRow key={index}>
                <TableCell sx={{ minWidth: 250 }}>
                  <Link
                    to={`/influencers/${influencer.user_screen_name}`}
                    target="_blank"
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        src={getUserProfile(influencer?.user_screen_name)}
                        sx={{ width: 38, height: 38, mr: 1 }}
                      />
                      <Stack py={0.7}>
                        <Typography
                          variant="body1"
                          color={theme.palette.text.primary}
                          sx={{
                            fontWeight: 500,
                            fontSize: ".82rem",
                            lineHeight: 1.4,
                          }}
                        >
                          {influencer.user_name}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={theme.palette.text.primary}
                          sx={{
                            fontSize: ".75rem",
                            fontWeight: 100,
                          }}
                        >
                          @{influencer.user_screen_name}
                        </Typography>
                      </Stack>
                    </Box>
                  </Link>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.relevant_tweets?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.relevant_tweets?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.buy_signal?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.buy_signal?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.sell_signal?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.sell_signal?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.positive_sentiment?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.positive_sentiment?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.negative_sentiment?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.negative_sentiment?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.overall?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.overall?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.engagement?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.engagement?.delta ?? 0)}
                  </Box>
                </TableCell>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={12}
                    >
                      {influencer?.final?.rank ?? "-"}
                    </Typography>
                    {determineBadge(influencer?.final?.delta ?? 0)}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SortedRankTable;
