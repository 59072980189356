import { Box, Typography, useTheme } from "@mui/material";
import SmallAreaChart from "../../../charts/SmallAreaChart";
import styles from "../screeners.module.scss";
import { useMemo } from "react";
import { calculateWeekPercentChange } from "../../../../utils/functions";

interface VisualPricesProps {
  prices: { coin: number; date: string; avg_price: number }[];
  latestPrice: string;
}

const VisualPrices: React.FC<VisualPricesProps> = ({ prices, latestPrice }) => {
  const theme = useTheme();

  const chartSeries = useMemo(() => {
    return [...prices]
      .slice(0, 6)
      .reverse()
      .map((price) => ({
        x: price.date,
        y: Number(price.avg_price.toFixed(10)),
      }));
  }, [prices]);

  if (prices.length === 0) {
    return <Typography color={theme.palette.text.primary}> - </Typography>;
  }

  return (
    <Box className={`${styles.cryptoCardChart} smallAreaChartContainer`}>
      <SmallAreaChart
        name="Coin"
        data={chartSeries}
        color={
          calculateWeekPercentChange(prices, latestPrice)
            .toString()
            .includes("-")
            ? "#FA637A"
            : "#75D6A1"
        }
      />
    </Box>
  );
};

export default VisualPrices;
