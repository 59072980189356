import { Box, Button, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboardLayout";
import styles from "./success.module.scss";
import { MdCheckCircle } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../features/store";
import useAuth from "../../../utils/useAuth";
import { useEffect } from "react";
import { confirmPayment } from "../../../features/membership/membershipSlice";

const Success: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { hash } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const isAuthValid = useAuth();

  const membershipState = useSelector((state: RootState) => state.membership);

  // useEffect(() => {
  //   if (hash) {
  //     dispatch(confirmPayment(hash)).unwrap();
  //   }
  // }, [hash]);

  return (
    <DashboardLayout title="Coinfident | Success Payment">
      <Box className={styles.successPaymentContainer}>
        <Box className={styles.successPayment}>
          <MdCheckCircle color={theme.palette.success.main} size={90} />
          <Typography variant="h3" component="h2" mb={2} color={theme.palette.text.primary}>
            Subscription Activated Successfully!
          </Typography>
          <Typography variant="body1" textAlign={"center"} mb={4} color={theme.palette.text.primary}>
            Congratulations! Your subscription has been successfully created. You can now enjoy additional features and enhanced
            capabilities within the app. Thank you for your support! 🚀
          </Typography>
          <Button variant="contained" fullWidth onClick={() => navigate("/")}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Success;
