import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { LuInfo } from "react-icons/lu";
import { calculateGuageValue, convertData } from "../../../utils/functions";
import { TechnicalSignalsProps } from "../interfaces";
import Loading from "../../common/loading";
import Guage from "../../guage";
import DefaultTooltip from "../../common/tooltip";
import { isEmpty } from "lodash";

const technicalTooltip =
  "This indicator utilizes classic indicators like moving averages and oscillators to forecast market trends.";

const spsTooltip =
  "This is Our proprietary AI-detected trading signal from authentic crypto influencers' and news outlets' tweets, updated hourly.";

const TechnicalSignals: React.FC<TechnicalSignalsProps> = ({
  signalState,
  technicalSignals,
}) => {
  const theme = useTheme();
  const [technicalSignal, setTechnicalSignal] = useState<any>(null);

  const calculateTechnicalGuageValue = (guageData: any) => {
    if (guageData && guageData !== undefined) {
      let sell = 0;
      let buy = 0;
      let neutral = 0;

      for (let i = 0; i < guageData.length; i++) {
        const action = guageData[i].action;
        if (action === "Sell") {
          sell++;
        } else if (action === "Buy") {
          buy++;
        } else if (action === "Neutral") {
          neutral++;
        }
      }

      const total = ((buy / (sell + buy + neutral)) * 100).toFixed(0);

      return {
        sell,
        buy,
        neutral,
        total,
      };
    }
  };

  useEffect(() => {
    if (technicalSignals) {
      setTechnicalSignal(
        (Number(
          calculateTechnicalGuageValue(
            convertData(technicalSignals)?.oscillators
          )?.total ?? 0
        ) +
          Number(
            calculateTechnicalGuageValue(
              convertData(technicalSignals)?.moving_averages
            )?.total ?? 0
          )) /
          2
      );
    }
  }, [technicalSignals]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "center", md: "space-evenly" },
        alignItems: "center",
      }}
    >
      <Box sx={{ flex: 1, maxWidth: 210 }}>
        <Typography
          align="center"
          variant="body2"
          component="h6"
          mt={4}
          color={theme.palette.text.primary}
        >
          SPS
          <DefaultTooltip title={spsTooltip}>
            <span style={{ marginLeft: 4 }}>
              <LuInfo />
            </span>
          </DefaultTooltip>
        </Typography>
        <Guage
          value={
            signalState?.influencerSignal
              ? calculateGuageValue(
                  signalState.influencerSignal?.signal[0] ?? 0,
                  signalState.influencerSignal?.signal[1] ?? 0,
                  signalState.influencerSignal?.signal.null ?? 0
                )
              : 50
          }
          isEmpty={isEmpty(signalState.influencerSignal?.signal) ? true : false}
        />
      </Box>

      <Box sx={{ flex: 1, maxWidth: 210 }}>
        <Typography
          align="center"
          variant="body2"
          component="h6"
          mt={4}
          color={theme.palette.text.primary}
        >
          Technical
          <DefaultTooltip title={technicalTooltip}>
            <span style={{ marginLeft: 4 }}>
              <LuInfo />
            </span>
          </DefaultTooltip>
        </Typography>

        {technicalSignals && !technicalSignal ? (
          <Loading />
        ) : (
          <Guage
            name="technical"
            value={technicalSignal ? technicalSignal : 0}
            isEmpty={technicalSignal ? false : true}
          />
        )}
      </Box>
    </Box>
  );
};

export default TechnicalSignals;
