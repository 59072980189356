import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography, useTheme } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { getDifferenceInMonth } from "../../../utils/functions";
import moment from "moment";

interface SubscriptionAccordionProps {
  data: any;
}

const SubscriptionAccordion: React.FC<SubscriptionAccordionProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <>
      {data?.map((item: any) => (
        <Accordion>
          <AccordionSummary expandIcon={<MdKeyboardArrowDown size={25} />} aria-controls="panel1-content" id="panel1-header">
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} pr={1}>
              <Typography color={theme.palette.text.primary}>{item?.plan}</Typography>
              <Typography
                color={theme.palette.text.primary}
              >{`${getDifferenceInMonth(item?.current_period_start, item?.current_period_end).durationIntervalCount} ${getDifferenceInMonth(item?.current_period_start, item?.current_period_end).durationIntervalType}`}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Start
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {moment(item?.current_period_start).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                End
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {moment(item?.current_period_end).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Box>
            <Chip label={item?.status} color={item.status === "active" || item.status === "trialing" ? "primary" : "error"} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default SubscriptionAccordion;
