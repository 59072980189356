import { Typography, useTheme } from "@mui/material";
import { formatNumber } from "../../../../utils/functions";

interface VolumeProps {
  volume: number;
}

const Volume24: React.FC<VolumeProps> = ({ volume }) => {
  const theme = useTheme();

  if (!volume) {
    return <Typography color={theme.palette.text.primary}> - </Typography>;
  }

  return (
    <Typography
      variant="caption"
      component="h6"
      sx={{ whiteSpace: "nowrap" }}
      color={theme.palette.text.primary}
    >
      {formatNumber(volume).toLocaleString()}
    </Typography>
  );
};

export default Volume24;
