import { Box, Typography, useTheme } from "@mui/material";
import styles from "../screeners.module.scss";
import { Link } from "react-router-dom";
import Avatar from "../../../common/avatar";

interface TopInfluencersProps {
  topInfluencers: { screen_name: string; user_id_str: string }[];
  id: string;
}

const TopInfluencers: React.FC<TopInfluencersProps> = ({
  topInfluencers,
  id,
}) => {
  const theme = useTheme();

  if (topInfluencers?.length > 0) {
    return (
      <Box className={styles.coinsTopInfluencers}>
        {topInfluencers.slice(0, 3).map((inf) => (
          <Link
            to={`/influencers/${inf.screen_name}`}
            target="_blank"
            state={{ id: inf.user_id_str }}
            key={`${id}-${inf.user_id_str}`}
            onClick={(e) => e.stopPropagation()}
          >
            <Avatar screenName={inf.screen_name} width={28} height={28} />
          </Link>
        ))}
      </Box>
    );
  }

  return <Typography color={theme.palette.text.primary}> - </Typography>;
};

export default TopInfluencers;
