import {
  Button,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { getDifferenceInMonth } from "../../../utils/functions";
import { InvoiceItem, InvoiceTableProps } from "./interface";
import moment from "moment";

const InvoiceTable: React.FC<InvoiceTableProps> = ({ tHead, tBody }) => {
  const theme = useTheme();
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tHead.map((head: string, index: number) => (
              <TableCell key={head} align={index === 0 ? "left" : "center"}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tBody?.map((row: InvoiceItem, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="left">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {moment(row.created).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {row.collection_method}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {row.status}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    window.open(row.hosted_invoice_url, "_blank");
                  }}
                >
                  <Typography variant="caption" color="primary">
                    Show
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
