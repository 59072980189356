import { Typography, useTheme } from "@mui/material";
import { coinDataType } from "../interface";
import CountUp from "react-countup";

interface TweetCountProps {
  tweetCount: number;
}

const TweetCount: React.FC<TweetCountProps> = ({ tweetCount }) => {
  const theme = useTheme();

  const renderTweetCount = () => {
    if (!tweetCount || tweetCount < 10) {
      return "<10";
    }
    return (
      <CountUp
        duration={3}
        start={1}
        end={Number(Number(tweetCount).toLocaleString())}
      />
    );
  };

  return (
    <Typography
      variant="caption"
      component="h6"
      sx={{ whiteSpace: "nowrap" }}
      color={theme.palette.text.primary}
    >
      {renderTweetCount()}
    </Typography>
  );
};

export default TweetCount;
