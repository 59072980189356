import React from "react";
import { InputAdornment, TextField, useTheme } from "@mui/material";
import { FiSearch } from "react-icons/fi";

interface SearchInputProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "Search for asset...",
  value,
  onChange,
}) => {
  const theme = useTheme();

  return (
    <TextField
      label={""}
      placeholder={placeholder}
      variant="standard"
      fullWidth
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        sx: {
          p: 1.5,
          "& input": {
            fontSize: "14px",
            fontWeight: 200,
            color: theme.palette.text.primary,
          },
          "& input::placeholder": {
            fontSize: "12px",
            color: theme.palette.text.primary,
            opacity: theme.palette.mode == "light" ? 1 : 0.7,
          },
        },
        startAdornment: (
          <InputAdornment position="start">
            <FiSearch
              size={16}
              color={theme.palette.text.primary}
              opacity={theme.palette.mode == "light" ? 1 : 0.7}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
