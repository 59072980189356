import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

interface TabSelectorProps {
  tabs: string[];
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
}

const TabSelector: React.FC<TabSelectorProps> = ({ tabs, selectedTab, setSelectedTab }) => {
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs orientation={"horizontal"} value={selectedTab} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            label={
              <Typography color={selectedTab === index ? theme.palette.primary.main : theme.palette.text.primary} fontSize={"0.8rem"}>
                {tab}
              </Typography>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default TabSelector;
