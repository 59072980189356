import TechnicalSignals from "../../../analytics/technicalSignals";
import ComingSoon from "../../../common/comingSoon";
import Loading from "../../../common/loading";

const CoinSignals = ({
  signalState,
  cryptoData,
}: {
  signalState: any;
  cryptoData: any;
}) => {
  return (
    <div>
      {signalState.influencerSignalLoading ? (
        <Loading />
      ) : (
        <TechnicalSignals
          signalState={signalState}
          technicalSignals={cryptoData?.technical_signals}
        />
      )}
    </div>
  );
};

export default CoinSignals;
