import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";

interface PaymentMethodAccordionProps {
  data: any;
}

const PaymentMethodAccordion: React.FC<PaymentMethodAccordionProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <>
      {data?.map((item: any) => (
        <Accordion>
          <AccordionSummary expandIcon={<MdKeyboardArrowDown size={25} />} aria-controls="panel1-content" id="panel1-header">
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} pr={1}>
              <Typography color={theme.palette.text.primary}>{`**** - **** - **** - ${item.card.last4}`}</Typography>
              <Typography color={theme.palette.text.primary}>{item.card.brand}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Name
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {item.billing_details.name || "-"}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Expiration date
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {`${item.card.exp_year}-${item.card.exp_month}`}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default PaymentMethodAccordion;
