import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import {
  coinDataType,
  headDataType,
  ScreenersProps,
  tableHeadType,
} from "./interface";
import styles from "./screeners.module.scss";
import { RootState } from "../../../features/store";
import { useSelector, shallowEqual } from "react-redux";
import { useMemo, useState } from "react";
import Fuse from "fuse.js";
import SearchInput from "../../common/searchInput";
import Assets from "./component/Assets";
import Gauge from "./component/Gauge";
import Influencers from "./component/TopInfluencers";
import PricePercentage from "./component/PricePercentage";
import TweetCount from "./component/TweetCount";
import PostChangeRate from "./component/PostChangeRate";
import MarketCap from "./component/MarketCap";
import Volume from "./component/Volume24";
import VisualPrices from "./component/VisualPrices";
import {
  calculateDayPercentChange,
  calculateGuageValue,
  calculateWeekPercentChange,
} from "../../../utils/functions";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ScreenersHeader from "./component/ScreenersHeader";

const tableHeads: tableHeadType = [
  {
    id: 1,
    title: "Assets",
    info: null,
  },
  {
    id: 2,
    title: "SPS",
    moreDetails: "(Social Prediction Signal)",
    info: "SPS (Social Prediction Signal) is an index based on just crypto influencers and news outlet’s hourly crypto-related tweets on X platform and proprietary AI Models to provide accurate “buy” and “not buy” signals for each coin. The accuracy of this signal is currently 86%.",
  },
  {
    id: 3,
    title: "Top Influencers",
    info: "Active crypto influencers and news outlets on X platform with over 5000 followers, known for their analytical content providing valuable insights on crypto topics. Excludes promotional, fake, and non-influential accounts.",
  },
  {
    id: 4,
    title: "Price Percentage Change",
    moreDetails: "(Last 24h)",
    info: null,
  },
  {
    id: 5,
    title: "Tweet Count",
    info: null,
  },
  {
    id: 6,
    title: "Tweet Count Change",
    moreDetails: "(7 days)",
    info: "The percentage of changes in the number of posts compared to the last seven days",
  },
  {
    id: 7,
    title: "Market Cap",
    info: null,
  },
  {
    id: 8,
    title: "24h Volume",
    info: null,
  },
  {
    id: 9,
    title: "Last 7 days price",
    info: null,
  },
];

// Sorting Helper Function
const getSortValue = (coin: coinDataType, column: number) => {
  switch (column) {
    case 1:
      return coin.label;
    case 2:
      return Object.keys(coin.signal).length > 0 && coin.tweet_count > 10
        ? calculateGuageValue(
            coin.signal[0] ?? 0,
            coin.signal[1] ?? 0,
            coin.signal.null ?? 0
          )
        : -1;
    case 3:
      return coin.top_influencers?.length || 0;
    case 4:
      return calculateDayPercentChange(coin.prices) || 0;
    case 5:
      return Number(coin.tweet_count) || 0;
    case 6:
      const diffrence =
        Number(coin.tweet_count) - Number(coin.last_tweet_counts);
      return (diffrence / Number(coin.last_tweet_counts)) * 100;
    case 7:
      return Number(coin.market_cap) || 0;
    case 8:
      return Number(coin.volume) || 0;
    case 9:
      return calculateWeekPercentChange(coin.prices, coin.latest_price);
    default:
      return coin.label;
  }
};

const Screeners: React.FC<ScreenersProps> = ({ timeframe }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const accountState = useSelector((state: RootState) => state.account);
  const cryptosState: any = useSelector(
    (state: RootState) => state.cryptos,
    shallowEqual
  );

  const isLoggedIn = accountState.isAuthenticated;

  // States
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<number>(2);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

  // Handle Search Query
  const handleSearch = (query: string) => {
    if (!isLoggedIn) {
      toast.error("Please log in to continue");
    } else {
      setSearchQuery(query.toLowerCase());
    }
  };

  // Configure Fuse.js options
  const fuseOptions = {
    keys: ["label", "id"],
    threshold: 0.3,
    includeScore: false,
  };

  // Filtered Coins Using useMemo
  const filteredCoins = useMemo(() => {
    const coins = cryptosState.screeners[timeframe] || [];

    if (!searchQuery) return coins;

    // Use Fuse.js for searching
    const fuse = new Fuse(coins, fuseOptions);
    return fuse.search(searchQuery).map((result) => result.item);
  }, [searchQuery, cryptosState.screeners, timeframe]);

  // Handle Sorting
  const handleSort = (column: number) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("desc");
    }
  };

  // Optimized Sorted Data
  const sortedData = useMemo(() => {
    let sorted = [...filteredCoins];

    sorted.sort((a, b) => {
      const aValue = getSortValue(a, sortColumn);
      const bValue = getSortValue(b, sortColumn);

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      } else {
        return sortDirection === "asc"
          ? String(aValue ?? "").localeCompare(String(bValue ?? ""))
          : String(bValue ?? "").localeCompare(String(aValue ?? ""));
      }
    });

    // Pinned Coins Priority
    if (cryptosState.pinnedCoins?.length > 0) {
      const pinnedItems = sorted.filter((item) =>
        cryptosState.pinnedCoins.some(
          (pinned: coinDataType) => pinned.id === item.coin_id
        )
      );
      const remainingItems = sorted.filter(
        (item) =>
          !cryptosState.pinnedCoins.some(
            (pinned: coinDataType) => pinned.id === item.coin_id
          )
      );

      return [...pinnedItems, ...remainingItems];
    }

    return sorted;
  }, [sortColumn, sortDirection, filteredCoins, cryptosState.pinnedCoins]);

  return (
    <Box className={styles.assetsWrapper}>
      <SearchInput value={searchQuery} onChange={handleSearch} />

      <Box
        className={styles.fullAccessBTN}
        sx={{ display: isLoggedIn ? "none" : "block" }}
      >
        <Button variant="contained" onClick={() => navigate("/login")}>
          Login For Full Access
        </Button>
      </Box>

      <TableContainer
        sx={{ maxHeight: 500 }}
        className={`customScrollbar ${
          theme.palette.mode === "light"
            ? "customScrollbar-light"
            : "customScrollbar-dark"
        }`}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ position: "sticky", top: 0, zIndex: 100 }}>
              {tableHeads.map((column: headDataType) => (
                <TableCell
                  key={column.id}
                  sx={{ cursor: "pointer" }}
                  align={column.id === 1 ? "left" : "center"}
                  onClick={() => handleSort(column.id)}
                >
                  <ScreenersHeader
                    column={column}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((coin: coinDataType, index: number) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={!isLoggedIn && index >= 5 ? styles.blurRow : ""}
              >
                <TableCell align="left" padding="none">
                  <Assets coin={coin} />
                </TableCell>
                <TableCell align="center">
                  <Gauge
                    tweetCount={coin.tweet_count}
                    signal={coin.signal}
                    lastSignal={coin.last_signal}
                  />
                </TableCell>
                <TableCell align="center">
                  <Influencers
                    topInfluencers={coin.top_influencers}
                    id={coin.id}
                  />
                </TableCell>
                <TableCell align="center">
                  <PricePercentage prices={coin.prices} />
                </TableCell>
                <TableCell align="center">
                  <TweetCount tweetCount={coin.tweet_count} />
                </TableCell>
                <TableCell align="center">
                  <PostChangeRate
                    tweetCount={coin.tweet_count}
                    lastTweetCount={coin.last_tweet_counts}
                  />
                </TableCell>
                <TableCell align="center">
                  <MarketCap marketCap={coin.market_cap} />
                </TableCell>
                <TableCell align="center">
                  <Volume volume={coin.volume} />
                </TableCell>
                <TableCell align="center">
                  <VisualPrices
                    prices={coin.prices}
                    latestPrice={coin.latest_price}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Screeners;
