import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography, useTheme } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import moment from "moment";

interface TransactionAccordionProps {
  data: any;
}

const TransactionAccordion: React.FC<TransactionAccordionProps> = ({ data }) => {
  const theme = useTheme();
  return (
    <>
      {data?.map((item: any) => (
        <Accordion>
          <AccordionSummary expandIcon={<MdKeyboardArrowDown size={25} />} aria-controls="panel1-content" id="panel1-header">
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} pr={1}>
              <Typography color={theme.palette.text.primary}>{item.payment_method_details.type}</Typography>
              <Typography color={theme.palette.text.primary}>
                {item.payment_method_details.card.brand} - {item.payment_method_details.card.last4}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Created
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {moment(item.created).format("YYYY-MM-DD HH:mm:ss")}
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography fontWeight={300} color={theme.palette.text.primary}>
                Amount
              </Typography>
              <Typography fontWeight={100} color={theme.palette.text.primary}>
                {item.currency === "usd" ? "$" : "₹"}
                {item.amount / 100}
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default TransactionAccordion;
