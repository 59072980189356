import moment from "moment";
import { CoinsStatisticsProps, CoinsProps } from "./interfaces";
// import { RootState } from "../features/store";
// import { useAppSelector } from "../customHook/useAppSelector";

export function getLinesCountOfAnElement(element: any) {
  const divHeight = element.offsetHeight;
  const lineHeight = 20;
  return Math.ceil(divHeight / lineHeight);
}

export function scrollToTop() {
  const root = document.getElementById("root_wrapper");
  root?.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

export function formatPrice(price: number): number {
  if (price >= 1) {
    return Number(Number(price).toFixed(3));
  } else {
    let decimalCount = 0;
    let tempNum = price;
    while (tempNum < 1 && tempNum !== 0) {
      tempNum *= 10;
      decimalCount++;
    }
    return Number(Number(price).toFixed(decimalCount + 2));
  }
}

export function formatNumber(num: number) {
  if (num >= 1000000000) {
    return "$" + (num / 1000000000).toFixed(2) + "B";
  }
  if (num >= 1000000) {
    return "$" + (num / 1000000).toFixed(2) + "M";
  }
  return num;
}

export function convertAgentName(agent_type: string) {
  switch (agent_type) {
    case "Influencers Explorer":
      return "InfluencerExplorer";
    case "Coinalysis":
      return "CoinAlysis";
    case "Model Maker":
      return "ModelMaker";
    case "Crypto Q&A":
      return "CryptoQA";
    case "Chain Guardian":
      return "ChainGaurdian";
    case "Trade 360":
      return "Trade360";
    case "TradeAgent":
      return "OutputTraderAgent";
  }
}

export function convertAgentType(agent_name: string) {
  switch (agent_name) {
    case "InfluencerExplorer":
      return "Influencers Explorer";
    case "Coinalysis":
      return "CoinAlysis";
    case "ModelMaker":
      return "Model Maker";
    case "CryptoQA":
      return "Crypto Q&A";
    case "ChainGaurdian":
      return "Chain Guardian";
    case "Trade360":
      return "Trade 360";
    case "TradeAgent":
      return "TradeAgent";
  }
}

export function calculateUnixTime(dateType: string) {
  const date = new Date();

  switch (dateType) {
    case "1H":
      return {
        start: moment(date).subtract(1, "hours").unix() * 1000,
        end: date.getTime(),
      };
    case "1D":
      return {
        start: moment(date).subtract(1, "days").unix() * 1000,
        end: date.getTime(),
      };
    case "1W":
      return {
        start: moment(date).subtract(7, "days").unix() * 1000,
        end: date.getTime(),
      };
    case "1M":
      return {
        start: moment(date).subtract(1, "months").unix() * 1000,
        end: date.getTime(),
      };
    case "1Y":
      return {
        start: moment(date).subtract(1, "years").unix() * 1000,
        end: date.getTime(),
      };
    case "All":
      return {
        start: moment(date).subtract(3, "years").unix() * 1000,
        end: date.getTime(),
      };
  }
}

export function calculateCoinAveragePrice(data: { priceUsd: string }[]) {
  let sum = 0;
  for (let i = 0; i < data?.length; i++) {
    sum += parseFloat(data[i]?.priceUsd);
  }

  const average = sum / data?.length;
  return average;
}

const calculatePostsChangeRate = (coin: CoinsStatisticsProps) => {
  const diffrence = coin.tweet_count - coin.last_tweet_counts;
  const changeRatePercent = (diffrence / Math.max(coin.tweet_count, coin.last_tweet_counts)) * 100;

  return changeRatePercent;
};

const calculateSocialSignal = (coin: CoinsStatisticsProps) => {
  const firstVal = coin.signal[0] ?? 0;
  const secondVal = coin.signal[1] ?? 0;
  const thirdVal = coin.signal?.null ?? 0;
  const score = calculateGuageValue(firstVal, secondVal, thirdVal);

  return score;
};

const calculateTweetCount = (coin: CoinsStatisticsProps) => {
  if (coin.tweet_count) {
    if (coin.tweet_count >= 10) {
      return coin.tweet_count + 103;
    } else {
      if (Object.values(coin.signal).reduce((acc, curr) => acc + curr, 0) >= 10) {
        return coin.tweet_count + 103;
      } else {
        return coin.tweet_count;
      }
    }
  } else {
    return 1;
  }
};

const calculateLastTweetCount = (coin: CoinsStatisticsProps) => {
  if (coin.last_tweet_counts) {
    if (coin.last_tweet_counts >= 10) {
      return coin.last_tweet_counts + 103;
    } else {
      if (Object.values(coin.signal).reduce((acc, curr) => acc + curr, 0) >= 10) {
        return coin.last_tweet_counts + 103;
      } else {
        return coin.last_tweet_counts;
      }
    }
  } else {
    return 1;
  }
};

export function convertCoins(coinsArray: [] | never[]) {
  //@ts-ignore
  if (coinsArray[0].coin) {
    const transformedArray = coinsArray
      // .sort((a: any, b: any) => a.coin.rank - b.coin.rank)
      .map((obj: CoinsStatisticsProps) => ({
        id: obj.coin.cmc_tag,
        coin_id: obj.coin.id,
        full_id:
          obj.coin.cmc_tag === "bnb" ? "binance-coin" : obj.coin.cmc_tag === "polkadot-new" ? "polkadot" : obj.coin.cmc_tag.toLowerCase(),
        label: obj.coin.code,
        title: obj.coin.name,
        logo: obj.coin.code.toLowerCase(),
        prices: obj.prices,
        latest_price: obj.latest_price.price,
        market_cap: obj.latest_price.market_cap,
        volume: obj.latest_price.volume,
        tweet_count: calculateTweetCount(obj),
        last_tweet_counts: calculateLastTweetCount(obj),
        signal: obj.signal,
        calc_model: obj.coin.calc_model,
        last_signal: obj.last_signal,
        top_influencers: obj.top_influencers,
        technical_signals: obj.technical_signals,
      }))
      // .sort((a: any, b: any) => calculatePostsChangeRate(b)  - calculatePostsChangeRate(a))
      .sort((a, b) =>
        Object.values(b.signal).reduce((acc, curr) => acc + curr, 0) >= 10
          ? //@ts-ignore
            calculateSocialSignal(b)
          : 0 - Object.values(a.signal).reduce((acc, curr) => acc + curr, 0) >= 10
            ? //@ts-ignore
              calculateSocialSignal(1)
            : 0
      );

    return transformedArray;
  }

  const transformedArray = coinsArray
    .filter((item: any) => item.calc_model)
    .map((obj: CoinsProps) => ({
      id: obj.cmc_tag,
      coin_id: obj.id,
      full_id: obj.cmc_tag === "bnb" ? "binance-coin" : obj.cmc_tag === "polkadot-new" ? "polkadot" : obj.cmc_tag.toLowerCase(),
      label: obj.code,
      title: obj.name,
      logo: obj.code.toLowerCase(),
    }));
  return transformedArray;
}

export function calculateGuageValue(negative: number, positive: number, nullVlaue: number) {
  const total = positive + negative + nullVlaue;

  if (positive === 0) {
    const value = (1 - negative / total) * 100;
    return Number(value.toFixed(0));
  } else {
    const value = (positive / total) * 100;
    return Number(value.toFixed(0));
  }
}

export const calcPostChangeRate = (tweet_count: number, last_tweet_counts: number) => {
  const diffrence = Number(tweet_count) - Number(last_tweet_counts);
  const changeRatePercent = (diffrence / Number(last_tweet_counts)) * 100;

  return changeRatePercent;
};

export const getLastSignalPercentage = (
  signal: { 0: number; 1: number; null: number },
  lastSignal: { 0: number; 1: number; null: number }
) => {
  const current = calculateGuageValue(signal[0] ?? 0, signal[1] ?? 0, signal?.null ?? 0);
  const last = calculateGuageValue(lastSignal[0] ?? 0, lastSignal[1] ?? 0, lastSignal?.null ?? 0);

  return Number(last - current);
};

export function calculateSPSValueChart(data: any[]) {
  let lastValidSpsValue: number | null = null;
  return data.map((signal, currentIndex) => {
    let spsValue: number | null = null;
    if (signal.sum > 0) {
      if (signal.one_signals === 0) {
        spsValue = (1 - signal.zero_signals / signal.sum) * 100;
      } else {
        spsValue = (signal.one_signals / signal.sum) * 100;
      }
      spsValue = Number(spsValue.toFixed(0));
      lastValidSpsValue = spsValue;
    }
    if (signal.sum < 10 || spsValue === null || isNaN(spsValue) || signal.sum === 0) {
      let foundReplacement = false;
      for (let i = currentIndex + 1; i < data.length; i++) {
        if (data[i].sum > 10) {
          if (data[i].one_signals === 0) {
            spsValue = (1 - data[i].zero_signals / data[i].sum) * 100;
          } else {
            spsValue = (data[i].one_signals / data[i].sum) * 100;
          }
          spsValue = Number(spsValue.toFixed(0));
          lastValidSpsValue = spsValue;
          foundReplacement = true;
          break;
        }
      }
      if (!foundReplacement) {
        spsValue = lastValidSpsValue ?? 0;
      }
    }
    return { ...signal, spsValue };
  });
}
export function updateData(data: any) {
  // Create a shallow copy of the data array to avoid direct mutation
  return data.map((currentItem: any) => {
    // Replace null with 0 for zero_signals and one_signals
    const zeroSignals = currentItem.zero_signals ?? 0;
    const oneSignals = currentItem.one_signals ?? 0;

    // Calculate the initial sum
    const sum = zeroSignals + oneSignals;

    // Return the updated item with the new sum value and updated signals
    return {
      ...currentItem, // Copy all original properties
      zero_signals: zeroSignals, // Replace null with 0
      one_signals: oneSignals, // Replace null with 0
      sum, // Add or overwrite the sum property
    };
  });
}
export function calculateWeekPercentChange(pricesArray: { coin: number; date: string; avg_price: number }[], latest_price: string) {
  const avgPrices = pricesArray.map((item: any) => item.avg_price);
  const firstAvgPrice = avgPrices.pop();
  const lastAvgPrice = avgPrices.shift();

  return avgPrices.length > 0 ? ((lastAvgPrice - firstAvgPrice) / Number(latest_price)) * 100 : 0;
}

export function calculateDayPercentChange(pricesArray: { coin: number; date: string; avg_price: number }[]) {
  //@ts-ignore
  const todayPrice = pricesArray?.at(0)?.avg_price ?? 0;
  //@ts-ignore
  const lastPrice = pricesArray?.at(-1)?.avg_price ?? 0;

  const priceChange = todayPrice - lastPrice;
  const priceChangePercent = (priceChange / lastPrice) * 100;

  return priceChangePercent;
}

export function calculateCoinLineChartValue(currentValue: number, maxArrayValue: number) {
  const minValue = 2;
  const maxValue = 100;

  const scaledValue = (currentValue / maxArrayValue) * (maxValue - minValue) + minValue;

  return Math.round(scaledValue);
}

export function getCoinImage(coinId: number) {
  return `https://pwa.coinfident.ai/i/api/rest/media/coin_imgs/${coinId}.png`;
}

export function tradingViewSymbol(symbol: string) {
  switch (symbol) {
    case "SHIB":
      return `COINBASE:SHIBUSD`;
    case "NFT":
      return "CRYPTO:NFTUSD";
    case "LUNC":
      return `BITMEX:BLUNC`;
    case "CSPR":
      return `CRYPTO:CSPRUSD`;
    case "KCS":
      return `PYTH:KCSUSD`;
    case "XAUT":
      return `BITFINEX:XAUTUSD`;
    case "XEC":
      return `BITFINEX:XECUSD`;
    case "USDP":
      return `BITTREX:USDPUSD`;
    case "BSV":
      return `POLONIEX:BCHSVUSD`;
    case "OKB":
      return `BITMEX:BOKB`;
    case "TUSD":
      return `COINBASE:TUSD`;
    case "LEO":
      return `BITFINEX:LEOUSD`;
    case "DAI":
      return `BITSTAMP:DAIUSD`;
    case "USDC":
      return `GEMINI:USDCUSD`;
    case "USDT":
      return `BITSTAMP:USDTUSD`;
    case "GT":
      return `PYTH:GTUSD`;
    case "BUSD":
      return `PYTH:BUSDUSD`;
    case "XMR":
      return `xmr`;
    default:
      return `BINANCE:${symbol}USD`;
  }
}

export function selectedCoinsRegex(selectedCoinsArray: []) {
  const searchValue = selectedCoinsArray.map((coin: any) => `search=${coin}`).join("&");

  return searchValue;
}

export function sortTableData(columnName: string) {
  var selectedColumn = "";

  switch (columnName) {
    case "assets":
      selectedColumn = "label";
      break;
    case "top influencers":
      selectedColumn = "influencers";
      break;
    case "price":
      selectedColumn = "latest_price";
      break;
    case "sps\n(social prediction signal)":
      selectedColumn = "signal";
      break;
    case "score changes":
      selectedColumn = "last_signal";
      break;
    case "price percentage change\n(last 24h)":
      selectedColumn = "price";
      break;
    case "tweet count":
      selectedColumn = "tweet_count";
      break;
    case "tweet count change\n(7 days)":
      selectedColumn = "last_tweet_counts";
      break;
    case "24h volume":
      selectedColumn = "volume";
      break;
    case "market cap":
      selectedColumn = "market_cap";
      break;
    default:
      selectedColumn = columnName;
  }

  return selectedColumn;
}

export function calculateDate(type: string) {
  let end_date = "";

  switch (type) {
    case "1W":
      {
        end_date = moment().subtract(7, "days").format("YYYY-MM-DD");
      }
      break;
    case "1M":
      {
        end_date = moment().subtract(1, "months").format("YYYY-MM-DD");
      }
      break;
    case "1Y":
      {
        end_date = moment().subtract(12, "months").format("YYYY-MM-DD");
      }
      break;
    case "All":
      {
        end_date = moment().subtract(24, "months").format("YYYY-MM-DD");
      }
      break;
    default:
      {
        end_date = moment().subtract(24, "days").format("YYYY-MM-DD");
      }
      break;
  }

  return end_date;
}

export function convertData(data: any) {
  if (data) {
    const movingAveragesTable = [];
    const oscillatorsTable = [];

    for (const key in data.moving_averages) {
      const [value, action] = data.moving_averages[key];
      if (value && action) {
        movingAveragesTable.push({
          name: key,
          value: value,
          action: action,
        });
      }
    }

    for (const key in data.oscillators) {
      const [value, action] = data.oscillators[key];
      if (value && action) {
        oscillatorsTable.push({
          name: key,
          value: value,
          action: action,
        });
      }
    }

    return {
      moving_averages: movingAveragesTable,
      oscillators: oscillatorsTable,
    };
  }
}

export function influencerRankTitle(name: string) {
  switch (name) {
    case "sell_signal":
      return "Not-Buy SPS";
    case "positive_sentiment":
      return "Positive Sentiment";
    case "buy_signal":
      return "Buy SPS";
    case "negative_sentiment":
      return "Negative Sentiment";
    case "relevant_tweets":
      return "Tweet Count";
    case "btc_ranking":
      return "BTC Tweets";
  }
}

export function influencerTypePriority(name: string) {
  switch (name) {
    case "sell_signal":
      return 2;
    case "positive_sentiment":
      return 4;
    case "buy_signal":
      return 1;
    case "negative_sentiment":
      return 5;
    case "relevant_tweets":
      return 6;
    case "btc_ranking":
      return 3;
  }
}

export function convertSPSChangesPeriodName(key: string) {
  switch (key) {
    case "1_day":
      return "1D Change";
    case "3_day":
      return "3D Change";
    case "7_day":
      return "1W Change";
    case "14_day":
      return "2W Change";
    case "30_day":
      return "1M Change";
  }
}

export function findColorRange(arr: number[], value: number) {
  let color: string = "";

  const negativeColors = ["#E57771", "#EA8F8B", "#EEA8A5", "#F3C1BE"];

  const positiveColors = ["#E8DAD8", "#D0EBD8", "#BBE2C5", "#90D0A1", "#7AC78F", "#64BE7C"];

  const minValue = Math.min(...arr);
  const maxValue = Math.max(...arr);

  const negativeRangeSize = Math.abs(minValue) / 4;
  const positiveRangeSize = Math.abs(maxValue) / 6;

  const negativeRanges: { min: number; max: number; color: string }[] = [];
  const positiveRanges: { min: number; max: number; color: string }[] = [];

  for (let i = 0; i < 4; i++) {
    const rangeMin = minValue + i * negativeRangeSize;
    const rangeMax = minValue + (i + 1) * negativeRangeSize;

    negativeRanges.push({
      min: rangeMin,
      max: rangeMax - 0.0001,
      color: negativeColors[i],
    });
  }

  for (let i = 0; i < 6; i++) {
    const rangeMin = positiveRangeSize * i;
    const rangeMax = positiveRangeSize * (i + 1);

    positiveRanges.push({
      min: rangeMin,
      max: rangeMax - 0.0001,
      color: positiveColors[i],
    });
  }

  const totalRanges = [...negativeRanges, ...positiveRanges];

  for (const range of totalRanges) {
    if (value >= range.min && value <= range.max) {
      color = range.color;
      break;
    }
  }

  return color;
}

export function getDifferenceInMonth(startDate: string, endDate: string): { durationIntervalCount: number; durationIntervalType: string } {
  const d1 = new Date(startDate);
  const d2 = new Date(endDate);

  const yearDiff = d2.getFullYear() - d1.getFullYear();
  const monthDiff = d2.getMonth() - d1.getMonth();
  const dayDiff = d2.getDate() - d1.getDate();

  const durationIntervalCount = yearDiff * 12 + monthDiff + dayDiff;

  if (yearDiff > 0 || monthDiff > 0) {
    return {
      durationIntervalType: "Month",
      durationIntervalCount,
    };
  } else {
    return {
      durationIntervalType: "Day",
      durationIntervalCount,
    };
  }

  // return yearDiff * 12 + monthDiff + dayDiff;
}
export function getDatetimePeriod(value: string | null) {
  let startDate;
  switch (value) {
    case "3H":
      startDate = moment().utc().subtract(3, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
    case "6H":
      startDate = moment().utc().subtract(6, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
    case "12H":
      startDate = moment().utc().subtract(12, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
    case "24H":
      startDate = moment().utc().subtract(24, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
    default:
      startDate = moment().utc().subtract(24, "hours").format("YYYY-MM-DDTHH:mm:ss");
      break;
  }

  return { startDate, endDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss") };
}

export function getDatePeriod(value: string | null) {
  let startDate;
  switch (value) {
    case "24H":
      startDate = moment().subtract(1, "days").format("YYYY-MM-DD");
      break;
    case "3D":
      startDate = moment().subtract(3, "days").format("YYYY-MM-DD");
      break;
    case "1W":
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      break;
    case "2W":
      startDate = moment().subtract(14, "days").format("YYYY-MM-DD");
      break;
    case "1M":
      startDate = moment().subtract(1, "month").format("YYYY-MM-DD");
      break;
    case "3M":
      startDate = moment().subtract(3, "month").format("YYYY-MM-DD");
      break;
    default:
      startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
      break;
  }

  return { startDate, endDate: moment().format("YYYY-MM-DD") };
}

export function getDatePeriodTooltip(value: string | null) {
  let text;
  switch (value) {
    case "3H":
      text = "Last 3 Hours";
      break;
    case "6H":
      text = "Last 6 Hours";
      break;
    case "12H":
      text = "Last 12 Hours";
      break;
    case "24H":
      text = "Last 24 Hours";
      break;
    case "3D":
      text = "Last 3 Days";
      break;
    case "1W":
      text = "Last 1 Week";
      break;
    case "2W":
      text = "Last 2 Weeks";
      break;
    case "1M":
      text = "Last 1 Month";
      break;
    case "3M":
      text = "Last 3 Months";
      break;
    default:
      text = "Last 1 Week";
      break;
  }

  return text;
}

export const getCoinName = (coinList: any, coinid: any) => {
  const coinName = coinList?.find((coin: any) => coin.id === coinid).name;

  return coinName;
};

export const getCoinId = (paramCoin: string | undefined, state: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  return state?.find((coin: any) => coin.full_id == paramCoin)?.coin_id;
  console.log(paramCoin);
};

export function formatNumberThree(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getUniqueValues<T extends Record<string, any>>(obj1: T, obj2: T): Partial<T> {
  if (!obj1 || !obj2) {
    return {};
  }
  const uniqueObject: Partial<T> = {};

  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  allKeys.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      (uniqueObject as Record<string, any>)[key] = obj2[key];
    }
  });

  return uniqueObject;
}

export const calculateStartDate = (timePeriod: number): string => {
  const now = new Date();
  now.setDate(now.getDate() - timePeriod);
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const calculateEndDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
