import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AppDispatch, RootState } from "../../../features/store";
import { stripeCheckout } from "../../../features/membership/membershipSlice";

interface SaveCardModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paymentSession: boolean;
  setPaymentSession: React.Dispatch<React.SetStateAction<boolean>>;
  product: any;
}

const saveCardInfoData = {
  cancel_url: "https://app.coinfident.ai/subscription/payment-cancel",
  success_url: "https://app.coinfident.ai/subscription/payment-success",
};

const SaveCardModal: React.FC<SaveCardModalProps> = ({
  open,
  setOpen,
  paymentSession,
  setPaymentSession,
  product,
}) => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();

  const accountState: any = useSelector((state: RootState) => state.account);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    background: (theme: any) => theme.palette.common.solidCard,
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  const [counter, setCounter] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const counterDown = () => setCounter((prev) => prev - 10);
    if (counter > 0 && paymentSession) {
      const countDown = setInterval(counterDown, 1000);
      return () => clearInterval(countDown);
    } else {
      if (paymentSession) {
        handleSaveCardInfo();
      }
    }
  }, [counter, paymentSession]);

  const handleSaveCardInfo = async () => {
    setLoading(true);
    if (!accountState.isAuthenticated) {
      toast.error("You need to be logged in to proceed with payment");
      setLoading(false);
      setOpen(false);
    } else {
      try {
        dispatch(stripeCheckout(product.default_price))
          .unwrap()
          .then((res) => {
            if (res.status === 200) {
              const redirectUrl = res.data.url;
              window.open(redirectUrl, "_blank");
              setLoading(false);
              setPaymentSession(false);
              setOpen(false);
              setCounter(100);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Failed to proceed to payment. Please try again");
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCancel = () => {
    setCounter(100);
    setPaymentSession(false);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <Box mb={4}>
          <Typography variant={"h5"}>Your card is not connected</Typography>
          <Typography variant={"caption"}>
            To proceed, please connect your cord
          </Typography>
        </Box>
        <Box>
          <LoadingButton
            loading={loading}
            variant="outlined"
            color={"primary"}
            sx={{ mr: 1 }}
            onClick={handleSaveCardInfo}
          >
            {loading ? (
              "Connect Card"
            ) : (
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <Typography color={"primary"}>Connect Card</Typography>
                <CircularProgress
                  size={18}
                  variant="determinate"
                  value={counter}
                />
              </Box>
            )}
          </LoadingButton>
          <Button
            variant="outlined"
            color={"error"}
            sx={{ ml: 1 }}
            onClick={handleCancel}
          >
            <Typography color={"error"}>Cancel</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveCardModal;
