import { Typography, useTheme } from "@mui/material";
import { formatNumber } from "../../../../utils/functions";

interface MarketCapProps {
  marketCap: number;
}

const MarketCap: React.FC<MarketCapProps> = ({ marketCap }) => {
  const theme = useTheme();

  if (!marketCap) {
    return <Typography color={theme.palette.text.primary}> - </Typography>;
  }

  return (
    <Typography
      variant="caption"
      component="h6"
      sx={{ whiteSpace: "nowrap" }}
      color={theme.palette.text.primary}
    >
      {formatNumber(marketCap).toLocaleString()}
    </Typography>
  );
};

export default MarketCap;
