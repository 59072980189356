import { Box, Chip, Container, Grid, List, ListItem, Stack, Typography, useTheme } from "@mui/material";
import styles from "./planPreview.module.scss";
import { Slide } from "react-awesome-reveal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../features/store";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import {
  createSubscription,
  fetchPricesList,
  fetchProductsList,
  fetchSubscriptionsList,
  saveCardInfo,
  setSubscriptionData,
} from "../../../features/membership/membershipSlice";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useAuth from "../../../utils/useAuth";
import { LoadingButton } from "@mui/lab";
import SubPagesLayout from "../../../layout/subPagesLayout";
import { isEmpty, set } from "lodash";
import Loading from "../../../components/common/loading";
import DashboardLayout from "../../../layout/dashboardLayout";

type SubscriptionType = {
  current_period_end: string;
  current_period_start: string;
  status: string;
  id: string;
};

const PlanPreview = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const interval = searchParams.get("interval");
  const interval_count = searchParams.get("interval_count");

  const membershipState: any = useSelector((state: RootState) => state.membership);
  const accountState: any = useSelector((state: RootState) => state.account);

  // states
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthValid) {
      if (!membershipState.subscriptionsList) {
        dispatch(fetchSubscriptionsList());
      }
    }
  }, [isAuthValid]);

  useEffect(() => {
    if (isAuthValid && isEmpty(membershipState.pricesList)) {
      dispatch(fetchPricesList());
    }
  }, [isAuthValid]);

  useEffect(() => {
    if (isAuthValid && isEmpty(membershipState.productsList)) {
      dispatch(fetchProductsList());
    }
  }, [isAuthValid]);

  const activeSubscriptionData = useMemo<SubscriptionType | undefined>(() => {
    if (membershipState.subscriptionsList) {
      return membershipState.subscriptionsList?.find(
        (subscription: SubscriptionType) => subscription?.status === "active" || subscription?.status === "trialing"
      );
    }
  }, [membershipState.subscriptionsList]);

  const desiredPlan = useMemo(() => {
    return membershipState.productsList?.find((product: any) => product.name === name);
  }, [membershipState.productsList]);

  const desiredPrice = useMemo(() => {
    const data = membershipState.pricesList?.find(
      (price: any) =>
        price.recurring.interval_count === Number(interval_count) &&
        price.recurring.interval === interval &&
        price.nickname?.split(" ")?.at(0)?.toLowerCase() === name?.toLowerCase()
    );

    return data;
  }, [interval, interval_count, membershipState.pricesList]);

  const handleValue = (val: string): React.ReactElement | null => {
    let element: React.ReactElement | null = null;

    if (val.toLowerCase() === "true") {
      element = <FaRegCircleCheck size={18} style={{ color: "#2FDB7D" }} />;
    } else if (val.toLowerCase() === "false") {
      element = <CgCloseO size={20} style={{ color: "#FA637A" }} />;
    } else {
      element = (
        <Typography color={theme.palette.text.primary} component={"h6"}>
          {val}
        </Typography>
      );
    }

    return element;
  };

  const subscriptionData = {
    product_name: name,
    product_interval: interval,
    product_interval_count: Number(interval_count),
    collection_method: "charge_automatically",
  };

  const handleCreateSubscription = async () => {
    setPaymentLoading(true);
    if (!accountState.isAuthenticated) {
      toast.error("You need to be logged in to proceed with payment.");
      setPaymentLoading(false);
    } else {
      if (activeSubscriptionData) {
        toast.error(
          "You already have an active subscription. Please cancel your current subscription before trying to purchase a new one."
        );
        setPaymentLoading(false);
      } else {
        try {
          dispatch(createSubscription(subscriptionData))
            .unwrap()
            .then((res) => {
              if (res.status === 201) {
                setPaymentLoading(false);
                toast.success("Subscription actived successfully");
                navigate("/");
              }
            })
            .catch((err) => {
              console.log(err.response);
              toast.error("Sorry! We’re facing some issues. Please try again after some time");
            });
        } catch (error) {
          setPaymentLoading(false);
          console.log(error);
        }
      }
    }
  };

  return (
    <DashboardLayout title="Coinfident | Plan Review">
      <Box className={styles.planReviewContainer}>
        <Container maxWidth="lg">
          <Box className={styles.planReviewWrapper} pt={3}>
            <Box mt={2}>
              <Slide duration={1500} direction="up" triggerOnce>
                <Box
                  className={styles.planReviewSubscription}
                  sx={{
                    background: (theme) => (theme.palette.mode === "dark" ? theme.palette.grey[800] : `${theme.palette.primary.main}20`),
                  }}
                >
                  <Box>
                    <Box className={styles.planReviewContent} sx={{ borderBottomColor: `${theme.palette.text.primary} !important` }}>
                      <Typography color={theme.palette.text.primary} fontSize={"2rem"}>
                        Review Subscription
                      </Typography>
                      <Typography sx={{ color: theme.palette.text.primary }} fontSize={"0.9rem"}>
                        Confirm your subscription and proceed to checkout to complete your purchase
                      </Typography>
                    </Box>

                    {membershipState.productsListLoading ? (
                      <Loading />
                    ) : (
                      <Box>
                        <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                          <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
                            <Typography color={theme.palette.text.primary} fontSize={"2rem"}>
                              {desiredPlan?.name}
                            </Typography>
                            <Chip
                              size="small"
                              variant="outlined"
                              color="primary"
                              label={
                                <Typography color={theme.palette.primary.main} fontSize={"0.75rem"}>
                                  {`${interval_count} ${interval ? interval.charAt(0).toUpperCase() + interval.slice(1) : ""}`}
                                </Typography>
                              }
                            />
                          </Stack>
                          <Typography color={theme.palette.text.primary} fontSize={"2rem"}>
                            ${isNaN(+(desiredPrice?.unit_amount / 100).toFixed(0)) ? 0 : +(desiredPrice?.unit_amount / 100).toFixed(0)}
                          </Typography>
                        </Stack>

                        <List>
                          {desiredPlan?.metadata &&
                            Object.entries(desiredPlan?.metadata)?.map((item: any, index: number) => (
                              <ListItem key={index} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                <Typography component={"h6"} color={theme.palette.text.primary}>
                                  {item?.at(0)}
                                </Typography>

                                <Typography>{handleValue(item?.at(1))}</Typography>
                              </ListItem>
                            ))}
                        </List>

                        <LoadingButton
                          fullWidth
                          variant="contained"
                          loading={paymentLoading}
                          onClick={handleCreateSubscription}
                          sx={{ color: theme.palette.common.white }}
                        >
                          SUBSCRIBE
                        </LoadingButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Slide>
            </Box>
          </Box>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default PlanPreview;
