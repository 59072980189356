import React, { memo, useMemo } from "react";
import GaugeComponent from "react-gauge-component";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { MdOutlineArrowLeft, MdOutlineArrowRight } from "react-icons/md";
import styles from "./guage.module.scss";

const StatisticsGauge: React.FC<{
  value: number;
  lastValue: number;
  hasBackground?: boolean;
  isEmpty?: boolean;
}> = memo(({ value, lastValue, hasBackground = false, isEmpty = false }) => {
  const theme = useTheme();

  const { startAngle, endAngle, changePrice } = useMemo(() => {
    const calculatedStartAngle =
      lastValue < value
        ? (lastValue / 100) * 180 - 90
        : (value / 100) * 180 - 90;
    const calculatedEndAngle =
      lastValue < value
        ? (value / 100) * 180 - 90
        : (lastValue / 100) * 180 - 90;
    const calculatedChangePrice = Math.abs(Math.round(lastValue - value));

    return {
      startAngle: calculatedStartAngle,
      endAngle: calculatedEndAngle,
      changePrice: calculatedChangePrice,
    };
  }, [value, lastValue]);

  const pointerColor = hasBackground
    ? theme.palette.common.white
    : theme.palette.text.primary;

  const gaugeArcColor = useMemo(
    () => (lastValue < value ? "#25965990" : "#FA637A90"),
    [value, lastValue]
  );

  return (
    <Box className={styles.statisticsGaugeWrapper}>
      <Box sx={{ position: "relative", mb: 0.5 }}>
        <GaugeComponent
          style={{
            width: 80,
            height: 35,
            position: "relative",
            zIndex: 1,
          }}
          type="semicircle"
          value={value || 0}
          arc={{
            colorArray: isEmpty
              ? [theme.palette.grey["700"]]
              : ["#FA637A", "#CDD931", "#259659"],
            padding: 0,
            width: 0.18,
            gradient: true,
          }}
          pointer={{
            animate: true,
            length: isEmpty ? 0 : 0.6,
            width: isEmpty ? 0 : 10,
            color: pointerColor,
          }}
          labels={{
            valueLabel: { hide: true },
            tickLabels: { hideMinMax: true },
          }}
        />

        <Gauge
          margin={{ left: 0, bottom: 0, right: 0, top: 0 }}
          cx={30.5}
          cy={45}
          sx={{
            position: "absolute",
            bottom: -7,
            left: 9,
            [`& .${gaugeClasses.valueArc}`]: {
              fill: gaugeArcColor,
            },
          }}
          width={61}
          height={45}
          valueMin={0}
          valueMax={100}
          value={100}
          startAngle={isNaN(startAngle) ? 0 : startAngle}
          endAngle={isNaN(endAngle) ? 0 : endAngle}
          outerRadius={26}
          innerRadius={0}
          text=""
        />
      </Box>
      <Box className={styles.statisticsValues}>
        <Typography
          align="center"
          sx={{
            fontSize: "10px",
            color: pointerColor,
          }}
        >
          {isEmpty ? "No data" : `${value}%`}
        </Typography>
        {!isNaN(changePrice) && (
          <Stack
            direction="row"
            alignItems="center"
            position="absolute"
            top={0}
            left={value < lastValue ? 0 : "unset"}
            right={lastValue <= value ? 0 : "unset"}
            sx={{
              color: value < lastValue ? "#FA637A" : "#259659",
            }}
          >
            {value < lastValue && (
              <MdOutlineArrowLeft
                size={15}
                color="#FA637A"
                style={{ width: "unset", height: "unset", marginTop: "unset" }}
              />
            )}
            <Typography
              component="span"
              sx={{
                color: value < lastValue ? "#FA637A" : "#259659",
                fontSize: "9px",
              }}
            >
              {changePrice}%
            </Typography>
            {lastValue < value && (
              <MdOutlineArrowRight
                size={15}
                color="#259659"
                style={{ width: "unset", height: "unset", marginTop: "unset" }}
              />
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
});

export default StatisticsGauge;
